import styled from 'styled-components'
import { Devices, Spacings, Text } from '@styles'

const getButtonBackground = ({ theme, disabled, variant, type = 'primary' }, isHover = false) => {
	const colors = theme.palette.background

	if (disabled) return colors.disabled

	if (type === 'primary') {
		if (variant === 'destructive') {
			return isHover ? colors.red : colors.redLight
		} else if (variant === 'success') {
			return isHover ? colors.green : colors.greenLight
		} else {
			return isHover ? colors.blue : colors.blueLight
		}
	} else if (type === 'secondary') {
		if (variant === 'destructive') {
			return colors.redLightest
		} else if (variant === 'success') {
			return colors.greenLightest
		} else {
			return colors.blueLightest
		}
	} else if (type === 'tertiary') {
		if (isHover) {
			if (variant === 'destructive') {
				return colors.redLightest
			} else if (variant === 'success') {
				return colors.greenLightest
			} else {
				return colors.blueLightest
			}
		}
		return 'transparent'
	}
}

const getButtonTextColor = ({ theme, disabled, variant, type = 'primary' }, isHover = false) => {
	const colors = theme.palette.text

	if (disabled) return colors.disabled

	if (type === 'primary') {
		if (variant === 'success') {
			return colors.darkblack
		} else {
			return colors.white
		}
	} else if (type === 'secondary' || type === 'tertiary') {
		if (variant === 'destructive') {
			return colors.red
		} else if (variant === 'success') {
			return colors.green
		}
		return colors.blue
	}
}

const getBorderColor = ({ theme, disabled, variant, type = 'primary' }, isHover = false) => {
	const colors = theme.palette.background

	if (disabled) return colors.disabled

	if (type === 'secondary' && isHover) {
		if (variant === 'destructive') {
			return colors.red
		} else if (variant === 'success') {
			return colors.green
		} else {
			return colors.blue
		}
	}

	return 'transparent'
}

export const BasicButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

	outline: none;

	border-radius: ${Spacings.SPACING_2B};
	cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};

	color: ${getButtonTextColor};
	background: ${getButtonBackground};
	border: ${(props) => `1px solid ${getBorderColor(props)}`};

	margin: ${({ margin }) => margin || 0};

	&:hover {
		color: ${(props) => getButtonTextColor(props, true)};
		background: ${(props) => getButtonBackground(props, true)};
		border-color: ${(props) => getBorderColor(props, true)};
	}
`

const Button = styled(BasicButton)`
	min-height: ${({ xs }) => (xs ? '1.75rem' : '2.125rem')};
	padding: 0 ${Spacings.SPACING_4B};

	font-size: ${({ xs }) => (xs ? Text.SMALL : Text.MEDIUM)};
	line-height: 1;
	min-width: ${({ xs }) => (xs ? '5rem' : '6rem')};

	@media ${Devices.tablet} {
		min-height: ${({ small, xs }) => (xs ? '1.75rem' : small ? '2rem' : '2.5rem')};
		padding: 0 ${Spacings.SPACING_4B};
		font-size: ${({ small, xs }) => (xs ? Text.SMALL : small ? Text.MEDIUM : Text.LARGE)};
		min-width: ${({ small, xs }) => (xs ? '5rem' : small ? '6rem' : '10rem')};
	}
`

export default Button
