import React, { useMemo } from 'react'
import styled from 'styled-components'
import { ArrowDownward, ArrowUpward } from '@material-ui/icons'
import { Devices, Spacings, Text } from '@styles'
import { decimalSeparatedNumber, displayAmount } from '@utils'
import { H6 } from '@base'

const RecordWrapper = styled.div`
	background: ${({ theme }) => theme.palette.background.white};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	margin-bottom: ${Spacings.SPACING_4B};
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	height: 6rem;
	overflow: hidden;

	@media ${Devices.mobile} {
		border-radius: 0;
	}
	@media ${Devices.tablet} {
		border-radius: ${Spacings.SPACING_2B};
	}
`
const LeftSideWapper = styled.div`
	margin: ${Spacings.SPACING_3B};
`

const TitleWrapper = styled(H6)`
	color: ${({ theme }) => theme.palette.text.black};
`
const TotalWrapper = styled.div`
	font-size: ${Text.LARGE};
	margin: ${Spacings.SPACING_6} 0px;
	color: ${({ theme }) => theme.palette.text.darkblack};
	font-weight: bold;
`
const DetailsWapper = styled.div`
	font-size: ${Text.EXTRA_SMALL};
	color: ${({ theme }) => theme.palette.text.tableHeader};
	display: flex;
	align-items: center;
	& > svg {
		height: ${Text.SMALL};
		width: ${Text.SMALL};
		fill: ${({ checked, theme }) => (checked ? theme.palette.background.greenDark : theme.palette.text.red)};
	}
	& > span:nth-child(2) {
		font-weight: bold;
		margin: 0px ${Spacings.SPACING_2};
	}
`

const RightSideWapper = styled.div`
	position: relative;
	width: ${Spacings.SPACING_16B};
	height: 100%;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	&:before {
		background: ${({ checked, theme }) => (checked ? theme.palette.background.greenLightest : theme.palette.background.redLightest)};
		content: '';
		position: absolute;
		width: 16rem;
		height: 7.3rem;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		border-radius: 60%;
	}
	& > svg {
		fill: ${({ checked, theme }) => (checked ? theme.palette.background.greenDark : theme.palette.text.red)};
	}
`

const PercentWrapper = styled.span`
	color: ${({ checked, theme }) => (checked ? theme.palette.background.greenDark : theme.palette.text.red)};
`

export const getLastTwoMonthsData = (_convertData, keyName, selectMonth) => {
	if (!_convertData) return []
	const convertData = JSON.parse(JSON.stringify(_convertData))
	let currentDate = new Date()
	let currentMonth = currentDate.getMonth() + 1
	let lastMonths = currentMonth - 1
	let todayDate = currentDate.getDate()
	let lastTwoMonthsData = []
	const sortData = convertData?.sort((a, b) => b.month - a.month)?.sort((a, b) => b.year - a.year)
	const convert_data = selectMonth === 'OVERALL' ? [sortData[0]] : convertData
	for (let month = currentMonth; month >= lastMonths; month--) {
		let monthData = convert_data?.find((item) => item?.month === (month <= 0 ? month + 12 : month)) || 0
		if (monthData) {
			if (month === lastMonths) {
				monthData[keyName] = (monthData[keyName] * todayDate) / 30
			}
			lastTwoMonthsData.push(monthData[keyName] || 0)
		} else {
			lastTwoMonthsData.push(0)
		}
	}
	return lastTwoMonthsData
}

const percentage = (_data) => {
	// [(New Value - Old Value) / Old Value] x 100
	if (!_data[1]) return 0
	if (_data[0]) return ((_data[0] - _data[1]) / _data[1]) * 100
	return 0
}

const DashboradRecord = ({ title, initialData, keyName, icon, selectMonth, extraData = null, totalValue = 0 }) => {
	const _data = getLastTwoMonthsData(initialData, keyName, selectMonth)
	const _totalRecord = useMemo(() => {
		let tempRecord = ''
		switch (keyName) {
			case 'weight':
				tempRecord = decimalSeparatedNumber(_data[0] / 1000, { maximumFractionDigits: 0 }) + ' MT'
				break
			case 'amount':
				tempRecord = extraData ? displayAmount(_data[0] / 10000000) : displayAmount(_data[0] / 10000000) + ' Cr'
				break
			default:
				tempRecord = _data[0]
				break
		}
		return tempRecord
	}, [keyName, _data])

	const checked = percentage(_data) >= 0

	const calcPercentage = useMemo(() => {
		return (((_data[0] / 10000000).toFixed(2) / totalValue) * 100).toFixed(2)
	}, [_data, totalValue])

	return (
		<RecordWrapper>
			<LeftSideWapper>
				<TitleWrapper>{title}</TitleWrapper>
				<TotalWrapper>
					{_totalRecord} {extraData && ` ${extraData}`} {totalValue !== 0 && `(${calcPercentage} %)`}
				</TotalWrapper>
				<DetailsWapper checked={checked}>
					{checked ? <ArrowUpward fontSize='small' /> : <ArrowDownward fontSize='small' />}
					<PercentWrapper checked={checked}>{percentage(_data)?.toFixed(0)}%</PercentWrapper>
					<span>from last month</span>
				</DetailsWapper>
			</LeftSideWapper>
			<RightSideWapper checked={checked}>{icon}</RightSideWapper>
		</RecordWrapper>
	)
}

export default DashboradRecord
