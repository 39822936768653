import { Button, Carousal, H6, UploadMultipleFileButton } from '@base'
import styled from 'styled-components'
import { Spacings } from '@styles'
import { useDispatch } from 'react-redux'
import { toggleFileViewer } from '@data/state/action/root'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`
export const MessageHeading = styled(H6)`
	color: ${({ theme }) => theme.palette.text.label};
	margin-bottom: ${Spacings.SPACING_3B};
`

export const UploadPhotoWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	margin-top: ${({ topMargin }) => (topMargin ? Spacings.SPACING_3B : 0)};
`

const ImageHandler = ({ images = [], handler = () => {}, photosInView = 3, viewOnly, downloadAccess, deleteAccess }) => {
	const dispatch = useDispatch()
	const viewPhotoHandler = () => {
		dispatch(toggleFileViewer(true, { files: images }))
	}

	return (
		<Wrapper>
			<MessageHeading>
				{!images || images?.length === 0 ? 'No images added' : `${images?.length} image${images?.length > 1 ? 's' : ''} available`}
			</MessageHeading>
			{images?.length > 0 && <Carousal urls={images} photosInView={photosInView} onClick={viewPhotoHandler} />}
			<UploadPhotoWrapper topMargin={images?.length > 0}>
				<Button xs uppercase type='secondary' disabled={!images || images?.length < 1} onClick={viewPhotoHandler} margin='0 0 0 auto'>
					View All
				</Button>
				{!viewOnly && (
					<UploadMultipleFileButton
						xs
						margin={`0 0 0 ${Spacings.SPACING_3B}`}
						label='Upload'
						uppercase
						onChange={handler}
						acceptFile={'image/*'}
					/>
				)}
			</UploadPhotoWrapper>
		</Wrapper>
	)
}

export default ImageHandler
