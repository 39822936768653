import styled, { css } from 'styled-components'
import { Spacings, Text } from '@styles'

export const FieldWrapper = styled.div`
	position: relative;
	padding: ${({ isTopPadding, inline }) => (inline ? 0 : isTopPadding ? '0.5rem 0 0 0' : '0.5rem 0')};
`

const getLabelLeft = ({ small, readOnly }) => {
	if (small) {
		return readOnly ? '-0.25rem' : '0.25rem'
	}
	return readOnly ? '-0.25rem' : '0.5rem'
}

export const Label = styled.label`
	font-size: ${({ small }) => (small ? Text.EXTRA_SMALL : Text.SMALL)};
	font-weight: ${Text.BOLD_600};
	color: ${({ theme, focus, error }) => (focus ? theme.palette.text.blue : theme.palette.text.label)};
	position: absolute;
	top: 0;
	left: ${getLabelLeft};
	background: ${({ theme }) => theme.palette.background.white};
	padding: 0 ${Spacings.SPACING_1B};
	user-select: none;
	transition: left 0.15s ease-in-out, color 0.15s ease-in-out;
	z-index: 1;
	border-radius: 0.125rem;
	line-height: ${Text.LINE_HEIGHT_MIN};
`

export const InputTextStyle = ({ small }) => css`
	width: 100%;
	min-height: ${small ? '1.75rem' : '2.5rem'};
	display: flex;
	align-items: center;
	font-size: ${small ? Text.SMALL : Text.MEDIUM};
	opacity: ${({ show }) => (show ? 1 : 0)};
	visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
`

export const InputText = styled.div`
	${InputTextStyle}
	color: ${({ showingPlaceholder, theme }) => (showingPlaceholder ? theme.palette.background.border : theme.palette.text.darkblack)};
	position: absolute;
	top: 0.5rem;
	left: ${({ show }) => (show ? 0 : '0.75rem')};
	background: ${({ theme }) => theme.palette.background.white};
	border: ${({ show }) => (show ? 'none' : '1px solid transparent')};
	transition: left 0.15s ease-in-out ${({ show }) => (show ? '' : ', opacity 0.15s ease-in-out 0.15s, visibility 0.15s ease-in-out 0.15s')};

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`

export const StyledInput = styled.input`
	${InputTextStyle}
	padding: 0 ${Spacings.SPACING_3B};
	border-radius: ${Spacings.SPACING_2B};
	outline: none;
	border: 1px solid ${({ theme }) => theme.palette.background.border};
	color: ${({ theme }) => theme.palette.text.darkblack};

	transition: border-color 0.15s ease-in-out ${({ show }) => (show ? ', opacity 0.15s ease-in-out 0.15s, visibility 0.15s ease-in-out 0.15s' : '')};

	&:focus,
	&:hover {
		border-color: ${({ theme }) => theme.palette.background.blue};
	}

	&:invalid {
		border-color: ${({ theme }) => theme.palette.background.messageRed};
	}

	&:disabled {
		border-color: ${({ theme }) => theme.palette.background.border};
	}

	&::placeholder {
		color: ${({ theme }) => theme.palette.background.border};
		font-size: ${({ small }) => (small ? Text.SMALL : Text.MEDIUM)};
	}

	${({ focussed }) => {
		if (focussed) {
			return css`
				border-color: ${({ theme }) => theme.palette.background.blue};
			`
		}
	}}
`

export const ErrorMessage = styled.span`
	color: ${({ theme }) => theme.palette.text.messageRed};
	font-size: ${({ small }) => (small ? Text.MICRO_SMALL : Text.SMALL)};
	position: absolute;
	bottom: ${({ small }) => (small ? '-' + Spacings.SPACING_2 : 0)};
	left: 0.5rem;
	line-height: 1;
	padding: 0 ${Spacings.SPACING_1B};
	background: ${({ theme }) => theme.palette.background.white};
`
export const StyledTextArea = styled.textarea`
	${InputTextStyle}
	padding: ${Spacings.SPACING_3B};
	border-radius: ${Spacings.SPACING_2B};
	outline: none;
	border: 1px solid ${({ theme }) => theme.palette.background.border};
	color: ${({ theme }) => theme.palette.text.darkblack};

	transition: border-color 0.15s ease-in-out ${({ show }) => (show ? ', opacity 0.15s ease-in-out 0.15s, visibility 0.15s ease-in-out 0.15s' : '')};

	&:focus,
	&:hover {
		border-color: ${({ theme }) => theme.palette.background.blue};
	}

	&:invalid {
		border-color: ${({ theme }) => theme.palette.background.messageRed};
	}

	&:disabled {
		border-color: ${({ theme }) => theme.palette.background.border};
	}

	&::placeholder {
		color: ${({ theme }) => theme.palette.background.border};
		font-size: ${Text.MEDIUM};
	}

	resize: none;
`
export const MultiSelecteInputWrapper = styled.div`
	position: relative;
	padding-top: ${({ displayPills }) => (displayPills ? Spacings.SPACING_7B : 0)};
	transition: padding-top 0.15s ease-in-out;
`
