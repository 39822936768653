import React from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

const _Link = ({ children, href, ...props }) => (
	<RouterLink to={href} {...props}>
		{children}
	</RouterLink>
)

const Link = styled(_Link)`
	text-decoration: none;
	flex-grow: ${(props) => props.flexgrow || 'auto'};
`

export default Link
