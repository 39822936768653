import { forwardRef, memo, useCallback, useEffect, useRef, useState } from 'react'
import { FieldWrapper, InputText, Label, StyledInput, ErrorMessage } from './styles'

const Input = forwardRef(
	(
		{
			id,
			label,
			value,
			onChange,
			onFocus,
			onDirty,
			setValidity,
			hasError,
			errorMap,
			readOnly,
			inputReadOnly,
			disabled,
			uppercase,
			focussed,
			min,
			max,
			isTopPadding,
			inline,
			...props
		},
		ref
	) => {
		const elem = useRef()
		const isDirty = useRef(false)
		const [focus, setFocus] = useState(props?.autofocus)
		const [error, setError] = useState()

		const validate = useCallback(
			(elem) => {
				if ((!isDirty.current && elem.required) || (!value && !elem.required)) {
					setError(null)
					typeof setValidity === 'function' && setValidity(true)
					return
				}

				const validityState = elem.validity

				const isElementValid = validityState.valid

				if (isElementValid) {
					setError(false)
				} else {
					if (validityState.valueMissing) {
						setError(errorMap?.valueMissing ?? 'Input is required')
					} else if (validityState.patternMismatch) {
						setError(errorMap?.patternMismatch ?? 'Input does not match the criteria')
					} else if (validityState.rangeUnderflow) {
						setError(errorMap?.rangeUnderflow ?? 'Value is too low')
					} else if (validityState.rangeOverflow) {
						setError(errorMap?.rangeOverflow ?? 'Value is too high')
					} else if (validityState.tooLong) {
						setError(errorMap?.tooLong ?? 'Value is too long')
					} else if (validityState.tooShort) {
						setError(errorMap?.tooShort ?? 'Value is too short')
					} else if (validityState.typeMismatch) {
						setError(errorMap?.typeMismatch ?? 'Value is not of correct type')
					} else {
						setError('Input is invalid')
					}
				}

				typeof setValidity === 'function' && setValidity(isElementValid)
			},
			[errorMap, setValidity, value]
		)

		useEffect(() => {
			typeof hasError === 'function' && hasError(Boolean(error))
		}, [error])

		useEffect(() => {
			if (elem.current) {
				validate(elem.current)
			}
		}, [value])

		useEffect(() => {
			if (readOnly) {
				setError(null)
				setFocus(false)
				isDirty.current = false
			} else {
				validate(elem?.current)
			}
		}, [readOnly])

		useEffect(() => {
			if (disabled) {
				setFocus(false)
			}
		}, [disabled])

		const changeHandler = (e) => {
			if (typeof onChange !== 'function') return

			let value = e.target.value ?? ''
			if (uppercase) {
				value = value.toUpperCase()
			}
			if (props.type === 'number') {
				if (value === '') {
					onChange(value)
				} else {
					onChange(Math.abs(value))
				}
			} else if (props.type === 'tel') {
				const re = /^[0-9\b]+$/
				if (value === '' || re.test(value)) {
					onChange(value.trim())
				} 
			} else onChange(value)
		}
		const focusHandler = (e) => {
			const isFocus = e?.type === 'focus'

			setFocus(isFocus)

			typeof onFocus === 'function' && onFocus(isFocus)

			if (isFocus && !isDirty.current) {
				isDirty.current = true
				typeof onDirty === 'function' && onDirty(true)
			}
		}

		return (
			<FieldWrapper isTopPadding={isTopPadding} ref={ref} inline={inline}>
				<InputText show={readOnly} showingPlaceholder={value === undefined || value === null || value === ''}>
					{value || '-'}
				</InputText>
				<Label readOnly={readOnly} htmlFor={id} focus={focussed || focus} error={error}>
					{label}
				</Label>
				<StyledInput
					ref={(_ref) => (elem.current = _ref)}
					show={!readOnly}
					disabled={readOnly || disabled}
					id={id}
					value={value}
					onChange={changeHandler}
					onBlur={focusHandler}
					onFocus={focusHandler}
					uppercase={uppercase}
					onWheel={(e) => e.target.blur()}
					min={min}
					max={max}
					step='0.001'
					focussed={focussed}
					{...props}
					autoComplete='off'
					readOnly={!!inputReadOnly}
				/>
				{error && <ErrorMessage small={props.small}>{error}</ErrorMessage>}
			</FieldWrapper>
		)
	}
)

export default memo(Input)
