import React from 'react'
import styled, { withTheme, css } from 'styled-components'
import { NewReleases } from '@material-ui/icons'
import { Button } from '../'
import { Devices, Spacings, Text } from '@styles'

const EnquiryItemWrapper = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: ${Spacings.SPACING_3B};
	background: linear-gradient(180deg, rgba(234, 241, 250, 1) 0%, rgba(255, 254, 254, 1) 68%);
	border: 1px solid rgba(234, 241, 250, 1);
	margin-bottom: ${Spacings.SPACING_3B};
	transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
	// box-shadow: 0px ${Spacings.SPACING_1B} ${Spacings.SPACING_3B} 0.125rem ${({ theme }) => theme.palette.background.blue + '17'};
	${({ clickable }) => {
		if (clickable) {
			return css`
				cursor: pointer;
				&:hover {
					border-color: ${({ theme }) => theme.palette.background.blue};
				}
			`
		}
	}}
	${({ showBorder }) => {
		if (showBorder) {
			return css`
				border-color: ${({ theme }) => theme.palette.background.blue};
			`
		}
	}}
`

const InfoWrapper = styled.div`
	margin: ${Spacings.SPACING_3B} ${Spacings.SPACING_2B} ${Spacings.SPACING_2B};
	display: flex;
	flex-wrap: wrap;
	flex-grow: 1;
	user-select: none;
`

const BottomPanel = styled.div``

const ItemInfoWrapper = styled.div`
	width: 50%;

	${({ small }) => {
		if (small) {
			return css`
				width: 50%;
			`
		}
		return css`
			@media ${Devices.tablet} {
				width: 33%;
			}
			
		`
	}}
`

const InnerWrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: ${Spacings.SPACING_1B} ${Spacings.SPACING_2B};
	margin-bottom: ${Spacings.SPACING_2B};
	margin-right: ${Spacings.SPACING_2B};
`

const ItemInfoLabel = styled.div`
	font-size: ${Text.EXTRA_SMALL};
	color: ${({ theme }) => theme.palette.text.label};
	text-transform: uppercase;
	line-height: 1.6;
	display: flex;
	align-items: center;
`
const ItemInfoValue = styled.div`
	font-size: ${Text.MEDIUM};
	color: ${({ theme }) => theme.palette.text.primary};

	${({ overflow, wrap }) => {
		if (!overflow) {
			return css`
				white-space: ${wrap ? 'unset' : 'nowrap'};
				overflow: hidden;
				text-overflow: ellipsis;
			`
		}
	}}
`
const ActionViewText = styled(Button)``
const ViewMessageText = styled.span`
	color: ${({ theme }) => theme.palette.text.blue};
	display: inline-block;
	position: relative;
	cursor: pointer;

	& > span {
		visibility: hidden;
		opacity: 0;
		position: absolute;
		bottom: 110%;
		left: 50%;
		transform: translateX(-30%);
		padding: ${Spacings.SPACING_2B};
		font-size: ${Text.SMALL};
		background: ${({ theme }) => theme.palette.background.white};
		color: ${({ theme }) => theme.palette.text.black};
		box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
		border-radius: ${Spacings.SPACING_2B};
		transition: all 0.15s ease-in;
		display: inline-block;
		width: max-content;
		max-width: 180px;
		max-height: 120px;
		overflow: scroll;
		cursor: initial;

		/* Hide scrollbar for Chrome, Safari and Opera */
		&::-webkit-scrollbar {
			display: none;
		}
		/* Hide scrollbar for IE, Edge and Firefox */
		& {
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
		}

		@media ${Devices.tablet} {
			left: 0;
			transform: translateX(-10%);
		}
	}

	&:hover {
		& > span {
			visibility: visible;
			opacity: 1;
		}
	}
`

const IconWrapper = styled.div`
	display: flex;
	margin-left: ${Spacings.SPACING_1B};
	& > svg {
		font-size: ${Text.LARGE};
	}
`

const MessageView = ({ text }) => {
	return (
		<ViewMessageText>
			View
			<span>{text}</span>
		</ViewMessageText>
	)
}

const HighLightIcon = withTheme(({ theme }) => (
	<IconWrapper>
		<NewReleases htmlColor={theme.palette.background.yellow} />
	</IconWrapper>
))

const redirectAction = (actionUrl) => (e) => {
	e.stopPropagation()
	const a = window.open(actionUrl)
	a.focus()
}

const CloudInventoryCard = ({ data, id = Math.random() * 1000, small, clickable, clickHandler, renderBottomPanel, showBorder }) => {
    const viewClickHandler = () => {
		if (!clickable) return
		clickHandler(id)
    }

	return (
		<EnquiryItemWrapper clickable={clickable} showBorder={showBorder}>
			<InfoWrapper onClick={viewClickHandler}>
				{data.map(({ label, value, isMessage, highlight, actionUrl, wrap }, index) => {
					const overflow = isMessage && value?.length > 12
					if (overflow) {
						value = <MessageView text={value} />
					}
					if (actionUrl) {
						value = (
							<ActionViewText onClick={redirectAction(actionUrl)} small uppercase variant='destructive'>
								{value}
							</ActionViewText>
						)
					}
					return (
						<ItemInfoWrapper key={`iteminfo-${id}-${index}`} small={small}>
							<InnerWrapper>
								<ItemInfoLabel>
									{label}
									{highlight && <HighLightIcon />}
								</ItemInfoLabel>
								<ItemInfoValue overflow={overflow} wrap={wrap}>
									{value}
								</ItemInfoValue>
							</InnerWrapper>
						</ItemInfoWrapper>
					)
				})}
			</InfoWrapper>
			{renderBottomPanel && <BottomPanel>{renderBottomPanel}</BottomPanel>}
		</EnquiryItemWrapper>
	)
}

export default CloudInventoryCard
