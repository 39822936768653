import styled from 'styled-components'
import { H4 } from '../basic/Headings'
import { Devices, Spacings, Text } from '@styles'
import Button from '../basic/Button'

export const BaseWrapper = styled.div`
	padding: 0 ${Spacings.SPACING_4B};
	background: ${({ theme }) => theme.palette.background.white};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	@media ${Devices.tablet} {
		border-radius: ${Spacings.SPACING_2B};
	}
`

export const OutletWrapper = styled.section`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	height: 100%;
	@media ${Devices.tablet} {
		border-bottom-left-radius: ${Spacings.SPACING_2B};
		border-bottom-right-radius: ${Spacings.SPACING_2B};
	}
`
export const PageHeader = styled(H4)`
	font-weight: ${Text.BOLD_500};
	color: ${({ theme }) => theme.palette.text.tableHeader};
	line-height: ${Text.LINE_HEIGHT_MAX};
`
export const SectionHeader = styled(H4)`
	padding-bottom: ${({ isFinancialHeader }) => isFinancialHeader ? Spacings.SPACING_1 : Spacings.SPACING_3B};
	margin-bottom: ${Spacings.SPACING_3B};
	border-bottom: ${({ theme, noBorder }) => (noBorder ? 'none' : `1px solid ${theme.palette.background.searchgrey}`)};
	margin: ${({ margin }) => margin};
	display: flex;
	align-items: center;
	line-height: ${Text.LINE_HEIGHT_MAX};
	gap:${Spacings.SPACING_10};
	justify-content: space-between;
`
export const DrawerBodyWrapper = styled.div`
	padding: ${Spacings.SPACING_4B};
	height: 100%;
	overflow-y: scroll;
	flex-grow: 1;

	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}
	/* Hide scrollbar for IE, Edge and Firefox */
	& {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
`

export const PageLevelTableWrapper = styled.section`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	@media ${Devices.tablet} {
		padding-bottom: 1rem;
	}
`

export const FullHeightPageLevelTableWrapper = styled(PageLevelTableWrapper)`
	height: 100%;
`

export const DrawerFooterWrapper = styled.div`
	padding: ${Spacings.SPACING_4B};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	position: sticky;
	bottom: 0;
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;

	@media ${Devices.tablet} {
		justify-content: ${({ justifyContent }) => justifyContent || 'initial'};
	}
`

export const TableActionButton = styled(Button)`
	font-size: ${Text.SMALL};
	display: inline;
	min-width: 0;
	max-width: 100%;
	padding: 0 ${Spacings.SPACING_2B};
	overflow: hidden;
	margin: ${Spacings.SPACING_1B};
`
export const NavigationWrapper = styled.div`
	padding: 0 ${Spacings.SPACING_4B} ${Spacings.SPACING_4B};
	max-width: 100%;
	position: sticky;
	top: 0;
	z-index: 498;
	background: ${({ theme }) => theme.palette.background.main};

	@media ${Devices.tablet} {
		padding: 0 0 ${Spacings.SPACING_4B};
	}
`

export const TableActionButtonWrapper = styled.div`
	display: flex;
`

export const InfoMessageWrapper = styled.div`
	flex-basis: 100%;
	margin-bottom: ${Spacings.SPACING_4B};

	padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_3B};
	border-radius: ${Spacings.SPACING_2B};
	height: fit-content;

	background: ${({ theme }) => theme.palette.background.dark};
	@media ${Devices.tablet} {
		flex-basis: 20%;
		margin-bottom: 0;
	}
`
export const InfoMessage = styled.div`
	font-size: ${Text.SMALL};
	color: ${({ theme }) => theme.palette.text.white};
	line-height: 1.6;
	display: flex;
	margin-bottom: ${Spacings.SPACING_1B};
	&:last-of-type {
		margin-bottom: 0;
	}
	& > svg {
		font-size: ${Text.ULTRA_SMALL};
		margin-right: ${Spacings.SPACING_1B};
		margin-top: 5px;
	}
	@media ${Devices.tablet} {
		margin-bottom: ${Spacings.SPACING_1B};
	}
`

export const SlidabledComponentContainer = styled.div`
	opacity: ${({ show }) => (show ? '1' : '0')};
	visibility: ${({ show }) => (show ? 'visible' : 'hidden')};

	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: ${({ zIndex }) => zIndex ?? 498};
	background: ${({ theme }) => theme.palette.background.main};
`
export const SlidableContentWrapper = styled.div`
	height: 100%;
	display: flex;
`
