import { strings } from '@constants'

export const FactoryColumn = () => [
	{
		label: strings('order', 'id'),
		key: 'order_id',
	},
	{
		label: strings('order', 'date'),
		key: 'order_date',
	},
	{
		label: strings('factory', 'id'),
		key: 'factory_id',
	},
	{
		label: strings('partner'),
		key: 'partner',
	},
	{
		label: strings('partner', 'contact'),
		key: 'partner_contact',
	},
	{
		label: strings(['input', 'order', 'items']),
		key: 'input_order_items',
	},
	{
		label: strings(['output', 'order', 'items']),
		key: 'output_order_items',
	},
	{
		label: strings(['outgoing', 'order', 'id']),
		key: 'outgoing_order_id',
	},
	{
		label: strings('status'),
		key: 'status',
	},
]

export const UserColumn = () => [
	{
		label: strings('user', 'id'),
		key: 'user_id',
	},
	{
		label: strings('name'),
		key: 'full_name',
	},
	{
		label: strings('mobile'),
		key: 'mobile_number',
	},
	{
		label: strings('email', 'id'),
		key: 'email_id',
	},
	{
		label: strings('organisation', 'name'),
		key: 'organisation_name',
	},
	{
		label: strings('role'),
		key: 'role',
	},
	{
		label: strings('remarks'),
		key: 'remarks',
	},
	{
		label: strings(['is', 'user', 'active']),
		key: 'is_user_active',
	},
	{
		label: strings('categories'),
		key: 'categories',
	},
]

export const OrdersColumn = () => [
	{
		label: strings('order', 'id'),
		key: 'order_id',
	},
	{
		label: strings('order', 'date'),
		key: 'order_date',
	},
	{
		label: strings('buyer'),
		key: 'buyer',
	},
	{
		label: strings('buyer', 'contact'),
		key: 'buyer_contact',
	},
	{
		label: strings('supplier'),
		key: 'supplier',
	},
	{
		label: strings('products'),
		key: 'products',
	},
	{
		label: strings('ordered_weight_mt'),
		key: 'ordered_weight',
	},
	{
		label: strings('serviced_weight_mt'),
		key: 'serviced_weight',
	},
]

export const LedgersColumn = () => [
	{
		label: strings('date'),
		key: 'transaction_date',
	},
	{
		label: strings('source'),
		key: 'source',
	},
	{
		label: `${strings(['invoice_no', '/', 'transaction', 'number'])}`,
		key: 'transaction_number',
	},
	{
		label: strings('transaction', 'type'),
		key: 'transaction_type',
	},
	{
		label: strings('remarks'),
		key: 'transaction_remarks',
	},
	{
		label: strings('amount'),
		key: 'transaction_amount',
	},
]

export const DuePaymentsColumn = () => [
	{
		label: strings('id'),
		key: 'organisation_id',
	},
	{
		label: strings('organisation'),
		key: 'organisation',
	},
	{
		label: strings('current'),
		key: 'current',
	},
	{
		label: '0-7',
		key: '0-7',
	},
	{
		label: '\'07-15',
		key: '7-15',
	},
	{
		label: '15-30',
		key: '15-30',
	},
	{
		label: '30-45',
		key: '30-45',
	},
	{
		label: '45+',
		key: '45+',
	},
	{
		label: strings('total'),
		key: 'total',
	},
	{
		label: strings('interest'),
		key: 'interest',
	},
	{
		label: strings('sales', 'person'),
		key: 'sales_person',
	},
	{
		label: strings('region'),
		key: 'region',
	},
	{
		label: strings('platform'),
		key: 'platform',
	},
]

export const PaymentPastRecordColumn = () => [
	{
		label: strings('zoho', 'name'),
		key: 'zoho_name',
	},
	{
		label: strings('pan'),
		key: 'pan',
	},
	{
		label: strings('formatted', 'date'),
		key: 'formattedDate',
	},
	{
		label: strings('past', 'payment_terms'),
		key: 'pastPaymentsTerms',
	},
	{
		label: strings('average_past_delays'),
		key: 'averagePastDelays',
	},
	{
		label: strings('excess_ctc'),
		key: 'excessCTC',
	},
	{
		label: strings('gmv'),
		key: 'gmv',
	},
	{
		label: strings('gm'),
		key: 'gm',
	},
]

export const AdvanceToSupplierColumn = () => [
	{
		label: strings('id'),
		key: 'organisation_id',
	},
	{
		label: strings('organisation'),
		key: 'organisation',
	},

	{
		label: strings('total'),
		key: 'total',
	},

	{
		label: strings('supply', 'person'),
		key: 'supply_person',
	},
	{
		label: strings('region'),
		key: 'region',
	},
	{
		label: strings('platform'),
		key: 'platform',
	},
	{
		label: strings(['last', 'payment', 'Date']),
		key: 'lastPaymentDate',
	},
]

export const ActiveInvoices = () => [
	{
		label: strings('invoice_no'),
		key: 'invoice_number',
	},
	{
		label: strings('original_invoice_number'),
		key: 'original_invoice_number',
	},
	{
		label: strings('invoice', 'date'),
		key: 'invoice_date',
	},
	{
		label: strings('order_id'),
		key: 'order_id',
	},
	{
		label: strings('order_date'),
		key: 'order_date',
	},
	{
		label: strings('buyer'),
		key: 'buyer',
	},
	{
		label: strings('buyer_contact'),
		key: 'buyer_contact',
	},
	{
		label: strings('vehicle', 'number'),
		key: 'vehicle_number',
	},
	{
		label: strings('driver', 'number'),
		key: 'driver_number',
	},
	{
		label: strings('serviced_weight'),
		key: 'serviced_weight',
	},
	{
		label: strings('amount'),
		key: 'amount',
	},
	{
		label: strings('status'),
		key: 'status',
	},
]

export const PastRecordColumn = () => [
	{ key: 'name', label: strings('name'), mobile: true, align: 'left', exportOnly: true },
	{ key: 'zoho_name', label: strings('zoho', 'name'), cellWidth: 15, mobile: true, align: 'left' },
	{ key: 'pan', label: strings('pan'), mobile: false, align: 'left' },
	{ key: 'zoho_contact_id', label: strings('zoho_contact'), mobile: false, align: 'left', exportOnly: true },
	{ key: 'formatted_date', label: strings('date'), mobile: false, align: 'left' },
	{ key: 'past_payments_terms', label: strings(['past', 'payments', 'terms']), cellWidth: 10, mobile: false, align: 'left' },
	{ key: 'average_past_delays', label: strings('average_past_delays'), cellWidth: 10, mobile: true, align: 'left' },
	{ key: 'excess_ctc', label: strings('excess_ctc'), mobile: true, align: 'left' },
	{ key: 'gmv', label: strings('gmv'), mobile: true, align: 'left' },
	{ key: 'gm', label: strings('gm'), mobile: true, align: 'left' },
]

export const CollectionsColumn = () => [
	{
		label: strings('client'),
		key: 'client',
	},
	{
		label: strings('amount'),
		key: 'payment_amount',
	},

	{
		label: strings('mode_of_payment'),
		key: 'payment_mode',
	},

	{
		label: strings('expected', 'date'),
		key: 'expected_payment_date',
	},
	{
		label: strings('manager'),
		key: 'manager',
	},
	{
		label: strings('region'),
		key: 'region',
	},
	{
		label: strings('message'),
		key: 'message',
	},
	{
		label: strings('status'),
		key: 'status',
	},
]


export const BillsColumn = () => [
	{
		label: strings('invoice', 'number'),
		key: 'original_invoice_number',
	},
	{
		label: strings('invoice', 'amount'),
		key: 'invoice_amount',
	},
	{
		label: strings('invoice', 'date'),
		key: 'invoice_date',
	},
	{
		label: strings('organisation', 'name'),
		key: 'organisation_name'
	}
]


export const leadColumn = () => [
	{
		label: strings('created', 'at'),
		key: 'created_at'
	},
	{
		label: strings('created', 'by'),
		key: 'created_by'
	},
	{
		label: strings('mobile_number'),
		key: 'mobile_number'
	},
	{
		label: strings('organisation', 'id'),
		key: 'organisation_id'
	}, {
		label: strings('organisation', 'name'),
		key: 'organisation_name'
	},
	{
		label: strings('source'),
		key: 'source'
	}, {
		label: strings('status'),
		key: 'status'
	}, {
		label: strings('sales', 'manager'),
		key: 'sales_manager',

	}, {
		label: strings('business', 'category'),
		key: 'business_category'
	},
	{
		label: strings('location'),
		key: 'location'
	}
]
export const OrderBooksColumn = () => [
	{
		label: '#SPO',
		key: 'so_number',
	},
	{
		label: 'Supplier',
		key: 'supplier_name',
	},
	{
		label: 'SPO Amount',
		key: 'spo_amount',
	},
	{
		label: 'SPO Weight',
		key: 'spo_quantity',
	},
	{
		label: '#Order',
		key: 'order_id',
	},
	{
		label: 'Order Date',
		key: 'order_date',
	},
	{
		label: '#Enquiry',
		key: 'enquiry_id',
	},
	{
		label: '#PO',
		key: 'po_number',
	},
	{
		label: 'Buyer',
		key: 'customer_name',
	},
	{
		label: 'Region',
		key: 'region',
	},
	{
		label: 'Platform',
		key: 'platform',
	},
	{
		label: 'Terms',
		key: 'payment_terms',
	},
	{
		label: 'Address',
		key: 'shipping_address',
	},
	{
		label: 'Sales Person',
		key: 'sales_person_name',
	},
	{
		label: 'SPOC',
		key: 'supply_spoc_name',
	},
	{
		label: 'Order Weight',
		key: 'ordered_weight',
	},
	{
		label: 'Order Amount',
		key: 'ordered_amount',
	},

	{
		label: 'Serviced Amount',
		key: 'serviced_amount',
	},

	{
		label: 'Serviced Weight',
		key: 'serviced_weight',
	},

	{
		label: 'Pending weight',
		key: 'pending_quantity',
	},
]
