import { memo } from 'react'
import styled, { css } from 'styled-components'
import { Spacings, Text } from '@styles'

const TickText = styled.text`
	font-size: ${Text.SMALL};
	fill: ${({ theme }) => theme.palette.text.black};
`

const CustomToolTipWrapper = styled.div`
	background: ${({ theme }) => theme.palette.background.white};
	border-radius: ${Spacings.SPACING_2B};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	display: flex;
	flex-direction: column;
	font-size: ${Text.SMALL};
	padding: ${Spacings.SPACING_2B};
	color: ${({ theme }) => theme.palette.text.darkBlack};
	min-width: 5rem;
`

const Data = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: column;

	${({ label }) => {
		if (label) {
			return css`
				margin-bottom: ${Spacings.SPACING_2B};
				font-size: ${Text.MEDIUM};
				padding-bottom: ${Spacings.SPACING_2B};
				border-bottom: 1px solid ${({ theme }) => theme.palette.background.messageRed};
			`
		}
		return css`
			margin-bottom: ${Spacings.SPACING_1B};
			&:last-of-type {
				margin-bottom: 0;
			}
		`
	}}
`

export const CHART_COLORS = ['#1967D2bb', '#82ca9d', '#D77FA1']
export const PIE_CHART_COLORS = ['#82ca9d', '#9CB4CC', '#CB2F2Fbf', '#D77FA1', '#BAABDA', '#9ADCFF', '#68A7AD', '#BE8C63', '#F2D7D9', '#FFFBE7']
export const FILL_COLORS = ['#1967D2aa']

const tickFormatter = (value) => {
	if (value > 100000) {
		return `${value / 100000}L`
	}
	if (value > 1000) {
		return `${value / 1000}K`
	}
	return value
}

export const CustomizedXAxisTick = memo(({ x, y, stroke, payload }) => {
	return (
		<g transform={`translate(${x},${y})`}>
			<TickText x={0} y={0} dy={8} textAnchor='middle'>
				{payload.value}
			</TickText>
		</g>
	)
})

export const CustomizedYAxisTick = memo(({ x, y, stroke, payload }) => {
	return (
		<g transform={`translate(${x},${y})`}>
			<TickText x={0} y={0} dy={4} textAnchor='end'>
				{tickFormatter(payload.value)}
			</TickText>
		</g>
	)
})

export const CustomTooltip = ({ active, payload, label, inPieChart }) => {
	const renderPercentage = (percent) => {
		if (percent < 0.01) {
			return percent.toFixed(4)
		}
		return percent.toFixed(2)
	}

	if (active && payload && payload.length) {
		if (inPieChart) {
			const data = payload[0].payload
			return (
				<CustomToolTipWrapper>
					<Data label>
						<b>{data[data.labelKey]}</b>
					</Data>
					{payload.map((_p, index) => {
						if (_p?.payload.isOthers) {
							return _p?.payload.categories
								.sort((a, b) => b.percent - a.percent)
								.slice(0, 5)
								.map((_c, _index) => (
									<Data key={`tooltip-data-${_index}`}>
										{_c[_p?.payload.labelKey]}
										<div>
											<b>
												{typeof _p?.payload.formatter === 'function'
													? _p?.payload.formatter(_c[_p?.dataKey])
													: _c[_p?.dataKey]}{' '}
												{_p?.payload?.unit}
											</b>
											{'  '}({renderPercentage(_c.percent * 100)}%)
										</div>
									</Data>
								))
						}
						return (
							<Data key={`tooltip-data-${index}`}>
								<div>
									<b>
										{typeof _p?.payload.formatter === 'function'
											? _p?.payload.formatter(_p.payload[_p?.dataKey])
											: _p.payload[_p?.dataKey]}{' '}
										{_p?.payload?.unit}
									</b>
									{'  '}({renderPercentage(_p.payload.percent * 100)}%)
								</div>
							</Data>
						)
					})}
				</CustomToolTipWrapper>
			)
		}
		return (
			<CustomToolTipWrapper>
				<Data label>
					<b>{label}</b>
				</Data>
				{payload.map((_p, index) => (
					<Data key={`tooltip-data-${index}`}>
						{_p.name}
						<div>
							<b>
								{typeof _p.formatter === 'function' ? _p.formatter(_p.payload[_p?.dataKey]) : _p.payload[_p?.dataKey]} {_p?.unit}
							</b>
						</div>
					</Data>
				))}
			</CustomToolTipWrapper>
		)
	}

	return null
}
