import styled, { css } from 'styled-components'
import { Spacings, Devices, Text } from '@styles'
import { Link } from '../../'
import { useEffect, useState } from 'react'

const TabsWrapper = styled.section`
	display: flex;
	min-height: 2.25rem;
	background: ${({ theme }) => theme.palette.background.white};
	overflow: hidden;

	overflow-x: scroll;

	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}
	/* Hide scrollbar for IE, Edge and Firefox */
	& {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}

	width: fit-content;
	border-radius: ${Spacings.SPACING_2B};
`
const Tab = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_4B};
	font-size: ${Text.MEDIUM};
	font-weight: ${Text.BOLD_500};
	letter-spacing: 0.2px;
	flex-basis: 100%;
	background: ${({ theme }) => theme.palette.background.white};
	color: ${({ theme }) => theme.palette.text.darkblack};
	cursor: pointer;
	transition: color 0.15s ease-in-out;
	height: 100%;
	border: 1px solid ${({ theme }) => theme.palette.background.white};

	${({ first }) => {
		if (first) {
			return css`
				border-top-left-radius: ${Spacings.SPACING_2B};
				border-bottom-left-radius: ${Spacings.SPACING_2B};
			`
		}
	}}
	${({ last }) => {
		if (last) {
			return css`
				border-top-right-radius: ${Spacings.SPACING_2B};
				border-bottom-right-radius: ${Spacings.SPACING_2B};
			`
		}
	}}
  
	${({ selected }) => {
		if (selected) {
			return css`
				background: ${({ theme }) => theme.palette.background.blue + '01'};
				border-color: ${({ theme }) => theme.palette.text.blue};
				color: ${({ theme }) => theme.palette.text.blue};
			`
		} else {
			return css`
				&:hover {
					color: ${({ theme }) => theme.palette.text.darkblack};
					box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
					clip-path: inset(0px -${Spacings.SPACING_5B} 0px 0px);
				}
			`
		}
	}}
	min-width: max-content;
	width: max-content;

	${({ styles }) => styles}
`

const Tabs = ({ data, ...props }) => {
	const [selectedTab, setSelectedTab] = useState()

	useEffect(() => {
		if (selectedTab instanceof HTMLElement) {
			!props?.fixed && selectedTab.scrollIntoView({ behavior: 'smooth' })
		}
	}, [selectedTab])

	return (
		<TabsWrapper {...props}>
			{data
				.filter((tab) => !tab.hide)
				.map((tab, index) => {
					if (tab.href) {
						return (
							<Link href={tab.href} key={`tabs-${tab.label}-${index}`}>
								<Tab
									first={index === 0}
									last={index === data?.length - 1}
									selected={tab.selected}
									ref={(_ref) => tab.selected && setSelectedTab(_ref)}
									styles={tab?.styles}
								>
									{tab.label}
								</Tab>
							</Link>
						)
					}

					return (
						<Tab
							first={index === 0}
							last={index === data?.length - 1}
							selected={tab.selected}
							onClick={tab.onClick}
							ref={(_ref) => tab.selected && setSelectedTab(_ref)}
							key={`tab=${index}`}
							styles={tab?.styles}
						>
							{tab.label}
						</Tab>
					)
				})}
		</TabsWrapper>
	)
}

export default Tabs
