import styled from 'styled-components'
import { BarChart } from '@material-ui/icons'
import { H4 } from '../basic/Headings'
import { Spacings, Text } from '@styles'

const Wrapper = styled.div`
	background: ${({ theme }) => theme.palette.background.white};
	border-radius: ${Spacings.SPACING_2B};
	overflow: hidden;
`
const Header = styled.div`
	display: flex;
	align-items: center;

	padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_3B};
	background: ${({ theme }) => theme.palette.background.blue};
	color: ${({ theme }) => theme.palette.text.white};

	& > svg {
		font-size: ${Text.X_LARGE};
		margin-right: ${Spacings.SPACING_1B};
	}
`
const Body = styled.div`
	height: 100px;
`

const IconWrapper = styled.div``

const SummaryCard = ({ heading }) => {
	return (
		<Wrapper>
			<Header>
				<BarChart />
				<H4>{heading ?? 'Summary'}</H4>
			</Header>
			<Body></Body>
		</Wrapper>
	)
}

export default SummaryCard
