import styled, { css } from 'styled-components'
import { Spacings, Text } from '@styles'
import { strings } from '@constants'

const getChipStyle = ({ theme, status = '', color, background }) => {
	switch (status.toLowerCase()) {
		case strings('active').toLowerCase():
		case 'active':
		case 'invoice':
			return css`
				background: ${theme.palette.background.blue};
				color: ${theme.palette.text.white};
			`
		case 'success':
		case 'completed':
		case 'order_completed':	
		case 'deposit':
		case strings('quote_received').toLowerCase():
		case strings('completed').toLowerCase():
		case strings('success').toLowerCase():
		case 'paid':
			return css`
				background: ${theme.palette.background.green};
				color: ${theme.palette.text.primary};
			`
		case 'failure':
		case 'expired':
		case strings('order_lost').toLowerCase():
		case 'overdue':
		case 'credit_note':
			return css`
				background: ${theme.palette.background.messageRed};
				color: ${theme.palette.text.white};
			`
		case 'custom':
			return css`
				background: ${background};
				color: ${color};
			`
		default:
			return css`
				background: ${theme.palette.background.yellow};
				color: ${theme.palette.text.primary};
			`
	}
}

const ChipWrapper = styled.span`
	${getChipStyle}
	font-size: ${Text.EXTRA_SMALL};
	text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : '')};
	padding: ${Spacings.SPACING_1B} ${Spacings.SPACING_2B};
	line-height: 1;
	border-radius: ${Spacings.SPACING_10};
	margin: ${({ margin }) => margin || 0};
	display: inline-block;
	height: fit-content;
	width: fit-content;
`

const Chip = ({ label, uppercase = true, ...props }) => {
	return (
		<ChipWrapper uppercase={uppercase} {...props}>
			{label === 'UNVERIFIED' ? strings('in_review') : label}
		</ChipWrapper>
	)
}

export default Chip
