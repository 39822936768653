import { useEffect, useState } from 'react'
import PopTone from '@assets/sound/pop_tone.mp3'
import { useDispatch, useSelector } from 'react-redux'
import { playNotification } from '@data/state/action/root'

const NotificationPlayer = () => {
    const [audio] = useState(new Audio(PopTone));
    const dispatch = useDispatch()
    
    const notification = useSelector((state) => state.getIn(['root', 'notification']))

    useEffect(() => { 
        if (notification && notification.play) { 
            audio.play()
            dispatch(playNotification(false))
        }
    }, [notification])
    
    return null
}

export default NotificationPlayer