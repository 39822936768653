import { displayAmount } from '@utils'
import { getData } from './Columns'

export const auctionQuotedAmountColumn = ({ key = '', label, userId, ...extras }) => ({
	label,
	key: (row) => {
		const data = getData(row, key, extras?.data) ?? []
		const myQuote = data.find((_quote) => _quote?.buyer.user_id === userId)
		if (myQuote) {
			const totalQuotationAmount = myQuote.total_quotation_amount
			return {
				totalQuotationAmount,
			}
		}
	},
	renderer: (data) => {
		if (!data) return '-'
		return (
			<>
				<small>{displayAmount(data.totalQuotationAmount)}</small>
			</>
		)
	},
	...extras,
})

export const auctionYourBidColumn = ({ key = '', label, userId, ...extras }) => ({
	label,
	key: (row) => {
		const data = getData(row, key, extras?.data) ?? []
		const myQuote = data.find((_quote) => _quote?.buyer.user_id === userId)
		if (myQuote) {
			const quoteType = myQuote.quote_type
			const quoteAmount = myQuote.quote_per_mt
			return {
				quoteType,
				quoteAmount,
			}
		}
	},
	renderer: (data) => {
		if (!data) return '-'
		return (
			<>
				<div>
					<b>{displayAmount(data.quoteAmount)}</b>
				</div>
				<div>
					<small>({data.quoteType})</small>
				</div>
			</>
		)
	},
	...extras,
})
