import { Close } from '@material-ui/icons'
import styled from 'styled-components'
import { Spacings } from '@styles'

const MessageWrapper = styled.div`
	position: absolute;
	background: ${({ theme }) => theme.palette.background.white};
	border-bottom: 1px solid ${({ theme }) => theme.palette.background.searchgrey};
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_4B};
`
const TextWrapper = styled.div`
	flex-grow: 1;
	overflow: scroll;
	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}
	/* Hide scrollbar for IE, Edge and Firefox */
	& {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
	max-height: 100%;
	max-width: 100%;
`
const IconWrapper = styled.div`
	margin-left: ${Spacings.SPACING_4B};
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`

const Message = ({ text, show, setShow }) => {
	const closeHandler = (e) => {
		e.stopPropagation()
		setShow({})
	}

	if (!show) return null

	return (
		<MessageWrapper>
			<TextWrapper>{text}</TextWrapper>
			<IconWrapper>
				<Close fontSize='small' onClick={closeHandler} />
			</IconWrapper>
		</MessageWrapper>
	)
}

export default Message
