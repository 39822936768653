import React, { useMemo, memo } from 'react'
import { useSelector } from 'react-redux'
import styled, { withTheme, css } from 'styled-components'
import { NewReleases, Info } from '@material-ui/icons'
import { useNavigate } from 'react-router-dom'
import { Devices, Spacings, Text } from '@styles'
import { auctionStatus, roles, strings } from '@constants'
import { Button, Carousal } from '@base'
import { decimalSeparatedNumber, displayAmount } from '@utils'
import { paths } from '@routes'
import { H5, H6 } from '../basic/Headings'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { toggleFileViewer } from '@data/state/action/root'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: ${Spacings.SPACING_3B};
	background: linear-gradient(180deg, rgba(234, 241, 250, 1) 0%, rgba(255, 254, 254, 1) 68%);
	border: 1px solid rgba(234, 241, 250, 1);
	transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
	width: 100%;
	overflow: hidden;
	height: 100%;
	box-shadow: 0px ${Spacings.SPACING_1B} ${Spacings.SPACING_3B} 0.125rem ${({ theme }) => theme.palette.background.blue + '17'};
`

const InfoWrapper = styled.div`
	padding: ${Spacings.SPACING_3B} ${Spacings.SPACING_2B} ${Spacings.SPACING_1B};
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	user-select: none;
	background: ${({ theme }) => theme.palette.background.white};
`
const ItemInfoWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	padding: ${Spacings.SPACING_1B} ${Spacings.SPACING_2B};
	margin-bottom: ${Spacings.SPACING_1B};

	&:last-of-type {
		margin-bottom: 0;
	}
`

const ItemInfoLabel = styled.div`
	font-size: ${Text.EXTRA_SMALL};
	color: ${({ theme }) => theme.palette.text.label};
	text-transform: uppercase;
	display: flex;
	align-items: center;
	flex-basis: 50%;
	flex-shrink: 0;
`
const ItemInfoValue = styled.div`
	font-size: ${Text.SMALL};
	color: ${({ theme }) => theme.palette.text.black};
	font-weight: ${Text.BOLD_600};
	flex-basis: 50%;
	flex-shrink: 0;
	${({ overflow, wrap }) => {
		if (!overflow) {
			return css`
				white-space: ${wrap ? 'unset' : 'nowrap'};
				overflow: hidden;
				text-overflow: ellipsis;
			`
		}
	}};
`
const ViewMessageText = styled.span`
	color: ${({ theme }) => theme.palette.text.blue};
	display: inline-block;
	position: relative;
	cursor: pointer;

	& > span {
		visibility: hidden;
		opacity: 0;
		position: absolute;
		bottom: 110%;
		left: 50%;
		transform: translateX(-30%);
		padding: ${Spacings.SPACING_2B};
		font-size: ${Text.SMALL};
		background: ${({ theme }) => theme.palette.background.white};
		color: ${({ theme }) => theme.palette.text.black};
		box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
		border-radius: ${Spacings.SPACING_2B};
		transition: all 0.15s ease-in;
		display: inline-block;
		width: max-content;
		max-width: 180px;
		max-height: 120px;
		overflow: scroll;
		cursor: initial;

		/* Hide scrollbar for Chrome, Safari and Opera */
		&::-webkit-scrollbar {
			display: none;
		}
		/* Hide scrollbar for IE, Edge and Firefox */
		& {
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
		}

		@media ${Devices.tablet} {
			left: 0;
			transform: translateX(-10%);
		}
	}

	&:hover {
		& > span {
			visibility: visible;
			opacity: 1;
		}
	}
`

const IconWrapper = styled.div`
	display: flex;
	margin-left: ${Spacings.SPACING_1B};
	& > svg {
		font-size: ${Text.LARGE};
	}
`
const IconWrapperInfo = styled.div`
	display: flex;
	& > svg {
		font-size: ${Text.XX_LARGE};
	}
`
const ImageWrapper = styled.div`
	width: 100%;
	border-top-left-radius: ${Spacings.SPACING_2B};
	border-top-right-radius: ${Spacings.SPACING_2B};
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	cursor: pointer;
`

const Img = styled.img`
	max-height: 100%;
	max-width: 100%;
`

const PhotosInfo = styled.div`
	position: absolute;
	flex-direction: column;
	bottom: 0;
	left: 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({ theme }) => theme.palette.background.white};
	min-height: 3.5rem;

	box-shadow: 0px ${Spacings.SPACING_1B} ${Spacings.SPACING_3B} 0.125rem ${({ theme }) => theme.palette.background.blue + '17'};
`

const ActionButton = styled.a`
	color: ${({ theme }) => theme.palette.text.blue};
	cursor: pointer;
`

const Message = styled(H6)`
	color: ${({ theme }) => theme.palette.text.label};
	line-height: ${Text.LINE_HEIGHT_MAX};
`

const BidButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px ${Spacings.SPACING_1B} ${Spacings.SPACING_3B} 0.125rem ${({ theme }) => theme.palette.background.blue + '17'};
	padding: ${Spacings.SPACING_4B};
	min-height: 4rem;
`
const ButtonMessage = styled(H5)`
	font-weight: ${Text.BOLD_600};
	color: ${({ theme, active }) => (active ? theme.palette.text.messageRed : theme.palette.text.blue)};
`

const InfoIcon = withTheme(({ theme }) => (
	<IconWrapperInfo>
		<Info htmlColor={theme.palette.background.yellow} />
	</IconWrapperInfo>
))

const MessageView = ({ text }) => {
	return (
		<ViewMessageText>
			<InfoIcon />
			<span>{text}</span>
		</ViewMessageText>
	)
}

const HighLightIcon = withTheme(({ theme }) => (
	<IconWrapper>
		<NewReleases htmlColor={theme.palette.background.yellow} />
	</IconWrapper>
))

const ShopCard = memo(({ data, small, clickable, showBorder, status }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const user = useSelector((state) => state.getIn(['user', 'user']))

	const bidOnAuctionHandler = () => {
		navigate(paths.bid_on_auction(data?.auction_id))
	}

	const calculateTimeLeft = (openingTime) => {
		return moment(openingTime).fromNow()
	}

	const { active, details, imageDetails } = useMemo(() => {
		const active =
			moment(data?.auction_opening_time).isSameOrBefore(moment(Date())) &&
			moment(data?.auction_closing_time).isSameOrAfter(moment(Date())) &&
			status === auctionStatus.closing

		const imageDetails = {
			label: 'image',
			value: data?.auction_item?.product_photos,
		}

		const details = [
			{
				label: strings('product_name'),
				value: data?.auction_item?.product_name ?? '--',
				wrap: true,
			},
			{
				label: strings(['minimum', 'bid', 'price']) + '/(MT)',
				value: displayAmount(data?.auction_item?.mb_opening_price),
				highlight: true,
			},
			{
				label: strings('weight_mt'),
				value: decimalSeparatedNumber(data?.auction_item?.total_weight / 1000),
			},
			{
				label: strings('dispatch', 'location'),
				value: `${data?.dispatch_address?.city} , ${data?.dispatch_address?.state}`,
				wrap: true,
			},
			{
				label: strings('view', 'tc'),
				value: data?.auction_item?.tc_doc_path || '--',
				isTC: data?.auction_item?.tc_available || false,
			},
			{
				label: strings('info'),
				value: data?.auction_item?.message,
				isMessage: true,
				// highlight: true,
			},
		]
		return {
			active,
			details,
			imageDetails,
		}
	}, [status, data])

	const viewPhotoHandler = () => {
		dispatch(toggleFileViewer(true, { files: imageDetails?.value }))
	}

	const renderImages = () => (
		<ImageWrapper>
			<Carousal urls={imageDetails?.value ?? []} onClick={viewPhotoHandler} />
			{!imageDetails?.value?.length && (
				<PhotosInfo>
					<Message>No images available</Message>
				</PhotosInfo>
			)}
		</ImageWrapper>
	)

	const renderDetails = () => (
		<InfoWrapper>
			{details.map(({ label, value, isMessage, isTC, highlight, wrap }, index) => {
				const overflow = isMessage

				if (isTC) {
					const val = value
					value = (
						<ActionButton
							noAutoLeft
							xs
							uppercase
							type='tertiary'
							onClick={() => {
								dispatch(toggleFileViewer(true, { files: val }))
							}}
						>
							View
						</ActionButton>
					)
				}

				if (overflow) {
					value = value ? <MessageView text={value} /> : '--'
				}

				return (
					<ItemInfoWrapper key={`iteminfo-${index}`}>
						<ItemInfoLabel>
							{label}
							{highlight && <HighLightIcon />}
						</ItemInfoLabel>
						<ItemInfoValue overflow={overflow} wrap={wrap}>
							{value}
						</ItemInfoValue>
					</ItemInfoWrapper>
				)
			})}
		</InfoWrapper>
	)

	const renderBidButton = () => {
		if (active) {
			return (
				<BidButtonWrapper>
					<ButtonMessage active>Closing {calculateTimeLeft(data?.auction_closing_time)}</ButtonMessage>
					<Button xs type='primary' margin='0 0 0 auto' onClick={bidOnAuctionHandler}>
						{data.all_buyer_quotes?.find((quote) => quote.buyer.user_id === user.user_id) ? strings('see_quotes') : strings('bid')}
					</Button>
				</BidButtonWrapper>
			)
		} else {
			return (
				<BidButtonWrapper>
					<ButtonMessage>Opens {calculateTimeLeft(data?.auction_opening_time)}</ButtonMessage>
				</BidButtonWrapper>
			)
		}
	}

	return (
		<Wrapper clickable={clickable} showBorder={showBorder} small={small}>
			{renderImages()}
			{renderDetails()}
			{renderBidButton()}
		</Wrapper>
	)
})

export default ShopCard
