import styled from 'styled-components'
import Chip from '../../../basic/Chip'
import { Address } from '@data/model'
import { showDateFormat, decimalSeparatedNumber, displayAmount, getManager } from '@utils'
import { strings, enquiryStatus as enquiryStatusC } from '@constants'
import { Text } from '@styles'

const getAlignment = ({ align }) => {
	if (align === 'right') return 'flex-end'
	if (align === 'center') return 'center'
	return 'flex-start'
}

const ChipWapper = styled(Chip)`
	background: ${({ theme }) => theme.palette.text.grey};
	color: ${({ theme }) => theme.palette.text.darkblack};
`

export const getData = (row, key, data) => {
	let _data = data || row[key]
	if (typeof _data === 'function') {
		_data = _data(row, key)
	}
	return _data
}

export const idColumn = ({ key = 'enquiry_id', label = 'Enquiry ID', ...extras }) => ({
	label,
	key: (row) => {
		return getData(row, key, extras?.data)
	},
	...extras,
})

export const productsColumn = ({ key = 'enquiry_items', ...extras }) => ({
	label: 'Products',
	key: (row) => {
		const items = getData(row, key, extras?.data) ?? []
		const products = new Set()
		items.forEach((i) => {
			if (!products.has(i.category_name)) products.add(i.category_name)
		})

		return Array.from(products).map((i) => ({ name: i, id: `${row.id}_${i}` }))
	},
	renderer: (data = []) => {
		if (data.length === 0) return '-'
		const _data = data.length < 4 ? data : data?.slice(0, 2)
		const labelMsg = `+${data.length - _data.length} more`
		return (
			<>
				{_data?.map((item, i) => {
					return (
						<div index={i + item?.name}>
							<small>
								<b>{item?.name}</b>
								{_data.length - 1 === i ? '' : ','}
							</small>
						</div>
					)
				})}
				{data.length > 3 ? <ChipWapper label={labelMsg} uppercase={false} status={'custom'} /> : ''}
			</>
		)
	},
	...extras,
})

export const auctionProductColumn = ({ key = 'auction_item', ...extras }) => ({
	label: 'Product',
	key: (row) => {
		return getData(row, key, extras?.data) ?? {}
	},
	renderer: (data = {}) => {
		return (
			<>
				<div>
					<b>{data?.product_name}</b>
				</div>
				<div style={{ textTransform: 'uppercase' }}>
					<small>{data?.category_name}</small>
				</div>
			</>
		)
	},
	...extras,
})

export const dateColumn = ({ label = 'Enquiry Date', key = 'enquiry_date', ...extras }) => ({
	label,
	key: (row) => {
		const date = getData(row, key, extras?.data)
		if (!date) return '-'
		return showDateFormat(new Date(date))
	},
	...extras,
})

export const addressColumn = ({ key = 'shipping_address', label = 'Delivery Location', ...extras }) => ({
	label,
	key: (row) => {
		const address = getData(row, key, extras?.data)
		if (address) {
			return new Address(address)
		}
	},
	renderer: (data) => {
		if (!data) return '-'
		if (!data.city && !data.state) { 
			return <b>{data?.pincode}</b>
		}
		return (
			<>
				<div>
					<b>{data?.city}</b>
				</div>
				<div style={{ textTransform: 'uppercase' }}>
					<small>{data?.state}</small>
				</div>
			</>
		)
	},
	...extras,
})

export const weightColumn = ({ key = 'enquiry_items', label = 'Ordered Weight(MT)', weightKey = 'required_weight', ...extras }) => ({
	label: label,
	key: (row) => {
		const items = getData(row, key, extras?.data) ?? []
		let weight = 0
		if (key === 'auction_item') {
			weight = items[weightKey]
		} else {
			weight = items.reduce((acc, i) => acc + (i[weightKey] ?? 0), 0)
		}
		return decimalSeparatedNumber(weight / 1000)
	},
	...extras,
})

export const servicedWeightColumn = ({ key = '', label = 'Serviced Weight(MT)', ...extras }) => ({
	label: label,
	key: (row) => {
		return getData(row, key, extras?.data) ?? []
	},
	...extras,
})

export const priceColumn = ({ key = 'enquiry_items', priceKey = 'price_per_kg', weightKey = 'required_weight', ...extras }) => ({
	label: 'Order Amount',
	key: (row) => {
		const items = getData(row, key, extras?.data) ?? []
		const value = items.reduce((acc, i) => {
			return acc + (i[priceKey] ?? 0) * (i[weightKey] ?? 0)
		}, 0)

		return displayAmount(value)
	},
	...extras,
})

export const relationshipManagerColumn = ({ key = 'relationship_manager', type = 'sales', ...extras }) => ({
	label: 'Concerned Manager',
	key: (row) => {
		const value = getData(row, key, extras?.data) ?? []

		return getManager(value, type)
	},
	renderer: (data) => {
		if (!data) return '-'
		return (
			<>
				<div>
					<b>{data?.full_name}</b>
				</div>
				<div>
					<small>{data.mobile_number}</small>
				</div>
			</>
		)
	},
	...extras,
})

export const actionsColumn = ({ key = '', label = 'Actions', ...extras }) => ({
	label,
	key: (row) => {
		if (key) return getData(row, key, extras?.data)
		return row
	},
	renderer: (data) => null,
	...extras,
})

export const statusColumn = ({ key = 'enquiry_status', label = 'Enquiry Status', ...extras }) => ({
	label,
	key: (row) => {
		let status = getData(row, key, extras?.data)
		if (extras.user) {
			if (row.auction_status && row.auction_status === 'ORDER_PLACED') {
				if (row.all_buyer_quotes) {
					const buyer = row.all_buyer_quotes.find((_item) => _item.buyer.user_id === extras.user.user_id)
					if (buyer && !buyer.order_won) {
						status = strings('order_lost')
					}
				}
			}
			if (row.enquiry_status && row.enquiry_status === 'ORDER_PLACED') {
				if (row.all_supplier_quotes) {
					const supplier = row.all_supplier_quotes.find((_item) => _item.supplier.user_id === extras.user.user_id)
					if (supplier && !supplier.order_given) {
						status = strings('order_lost')
					}
				}
			}
			if (row.buyer) {
				if (extras.user.user_id === row.buyer.user_id && row.final_quote_buyer && status === enquiryStatusC.active) {
					status = strings('quote_received')
				}
			}
		}
		return status
	},
	renderer: (data) => {
		if (!data) {
			return
		}
		return <Chip label={data} status={data} />
	},
	...extras,
})

export const myQuoteColumn = ({ key = 'all_supplier_quotes', userId, ...extras }) => ({
	label: 'Your Quote',
	key: (row) => {
		const data = getData(row, key, extras?.data) ?? []
		const myQuote = data.find((_quote) => _quote?.supplier.user_id === userId)
		if (myQuote) {
			const quoteType = myQuote.quote_type

			const quoteAmount = myQuote.supplier_quote?.reduce((acc, item) => {
				if (quoteType.toLowerCase() === 'ex') return acc + item.ex_price * item.serviceable_weight
				return acc + item.for_price * item.serviceable_weight
			}, 0)

			return {
				quoteType,
				quoteAmount,
			}
		}
	},
	renderer: (data) => {
		if (!data) return '-'
		return (
			<>
				<div>
					<b>{displayAmount(data.quoteAmount)}</b>
				</div>
				<div>
					<small>({data.quoteType})</small>
				</div>
			</>
		)
	},
	...extras,
})

export const usernameColumn = ({ key = 'buyer', label = strings('created', 'by'), userId, ...extras }) => ({
	label,
	key: (row) => {
		const data = getData(row, key, extras?.data) ?? []

		if (!data) {
			return '-'
		}

		let tempValue = data
		if (data?.length > 0) {
			tempValue = data[0]
			if (!tempValue.user_id) {
				tempValue = tempValue[key]
			}
		}

		return tempValue
	},
	renderer: (data) => {
		if (!data) return '-'
		return (
			<>
				<div>
					<b>{(data?.organisation_name || data?.full_name) ?? '-'}</b>
				</div>
				<div>
					<small>{data.mobile_number}</small>
				</div>
			</>
		)
	},
	...extras,
})

export const LogisticsQuotesColumn = ({ key = 'all_logistics_quotes', label = 'Logistics Quotation', ...extras }) => ({
	label,
	key: (row) => {
		if (row) {
			if (row.lumpsum_logistics_quote !== 0) {
				return row.lumpsum_logistics_quote
			} else {
				return row.logistics_quote_per_mt * row.minimum_guaranteed_weight
			}
		}
	},
	renderer: (data) => {
		if (!data) return '-'
		return <b>{displayAmount(data)}</b>
	},
	...extras,
})

export const topQuotes = ({ key = 'all_supplier_quotes', label, userId, ...extras }) => ({
	label,
	key: (row) => {
		const data = getData(row, key, extras?.data) ?? []
		const myQuote = data.find((_quote) => _quote?.supplier.user_id === userId)

		if (myQuote) {
			const quoteType = myQuote.quote_type
			let quoteAmount = myQuote.supplier_quote?.reduce((acc, item) => {
				if (quoteType.toLowerCase() === 'for') return acc + item.for_price * item.serviceable_weight
			}, 0)
			const topForQuotes = row?.top_for_quotes ?? []
			let topQuote = strings('no')
			if (Math.max(...topForQuotes) >= quoteAmount) {
				topQuote = strings('yes')
			}

			return {
				quoteType,
				quoteAmount,
				topQuote,
				topForQuotes,
			}
		}
	},
	renderer: (data) => {
		if (data?.topForQuotes.length < 2 || data?.quoteType.toLowerCase() === 'ex') return '-'

		return (
			<>
				<div>
					<b>{data?.topQuote}</b>
				</div>
			</>
		)
	},
	...extras,
})

export const supplierList = ({ key = 'supplier_list', label = strings('supplier'), ...extras }) => ({
	label,
	key: (row) => {
		let data = getData(row, key, extras?.data) ?? []
		return data.map((item) => item?.supplier)
	},
	renderer: (data) => {
		if (data.length === 0) return '-'
		const _data = data.length < 4 ? data : data?.slice(0, 2)
		const labelMsg = `+${data.length - _data.length} more`
		return (
			<>
				{_data?.map((item, i) => {
					return (
						<div index={i + item?.mobile_number}>
							<small>
								<b>{(item?.organisation_name || item?.full_name) ?? item?.mobile_number}</b>
								{_data.length - 1 === i ? '' : ','}
							</small>
						</div>
					)
				})}
				{data.length > 3 ? <ChipWapper label={labelMsg} uppercase={false} status={'custom'} /> : ''}
			</>
		)
	},
	...extras,
})

export const enquiryDateColumn = ({ label = 'Enquiry Date', key = '', ...extras }) => ({
	label,
	key: (row) => {
		if (key) return getData(row, key, extras?.data)
		return row
	},
	renderer: (data) => {
		if (!data) return '-'
		return (
			<>
				<div>
					<b>{data?.enquiry_date ? showDateFormat(new Date(data?.enquiry_date)) : '-'}</b>
				</div>
				<div>
					<small>{data?.expiry_date ? showDateFormat(new Date(data?.expiry_date)) : '-'}</small>
				</div>
			</>
		)
	},
	...extras,
})

export const auctionDateColumn = ({ label, key = '', ...extras }) => ({
	label,
	key: (row) => {
		if (key) return getData(row, key, extras?.data)
		return row
	},
	renderer: (data) => {
		if (!data) return '-'
		return (
			<>
				<div>
					<b>{data?.auction_opening_time ? showDateFormat(new Date(data?.auction_opening_time)) : '-'}</b>
				</div>
				<div>
					<small>{data?.auction_closing_time ? showDateFormat(new Date(data?.auction_closing_time)) : '-'}</small>
				</div>
			</>
		)
	},
	...extras,
})
