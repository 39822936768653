import React from 'react'
import styled from 'styled-components'

const Spinner = () => (
	<StyledSpinner viewBox='0 0 50 50'>
		<circle className='path' cx='25' cy='25' r='20' fill='none' strokeWidth='4' />
	</StyledSpinner>
)

const StyledSpinner = styled.svg`
	border: 3px solid ${({ theme }) => theme.palette.background.light};
	border-radius: 50%;
	border-top: 3px solid ${({ theme }) => theme.palette.background.blueLight};
	width: 22px;
	height: 22px;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;

	/* Safari */
	@-webkit-keyframes spin {
		0% {
			-webkit-transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`

export default Spinner
