import styled from 'styled-components'

const RadioButtonWrapper = styled.span`
	height: ${({ size }) => (size ? size + 'rem' : '1.5rem')};
	width: ${({ size }) => (size ? size + 'rem' : '1.5rem')};
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
	border-radius: 50%;
	background: ${({ theme }) => theme.palette.background.white};
	box-shadow: inset 0 0 0px 2px ${({ theme, disabled }) => (disabled ? theme.palette.text.greydark : theme.palette.text.blue)};

	& > input {
		top: 0;
		left: 0;
		width: 100%;
		cursor: inherit;
		height: 100%;
		margin: 0;
		opacity: 0;
		padding: 0;
		z-index: 1;
		position: absolute;
	}

	& > svg {
		height: ${({ size }) => (size ? size + 'rem' : '1.5rem')};
		width: ${({ size }) => (size ? size + 'rem' : '1.5rem')};
		font-size: 1.5rem;
		transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		flex-shrink: 0;
		user-select: none;
		fill: ${({ checked, theme, disabled }) => (checked ? (disabled ? theme.palette.text.greydark : theme.palette.text.blue) : 'transparent')};
	}
`

const RadioButton = ({ id, size, value, checked, onChange, disabled, onClick = () => {} }) => {
	return (
		<RadioButtonWrapper size={size} checked={checked} disabled={disabled}>
			<input type='radio' id={id} value={value} checked={checked} disabled={disabled} onChange={onChange} onClick={onClick} />
			<svg focusable='false' viewBox='0 0 24 24'>
				<path d='M8.465 8.465C9.37 7.56 10.62 7 12 7C14.76 7 17 9.24 17 12C17 13.38 16.44 14.63 15.535 15.535C14.63 16.44 13.38 17 12 17C9.24 17 7 14.76 7 12C7 10.62 7.56 9.37 8.465 8.465Z'></path>
			</svg>
		</RadioButtonWrapper>
	)
}

export default RadioButton
