import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Spacings, Text } from '@styles'

const AccordionComponent = styled.div`
	// border: 1px solid ${({ theme }) => theme.palette.background.searchgrey};
	border-radius: ${Spacings.SPACING_2B};
	margin-bottom: ${Spacings.SPACING_2B};
	margin: ${({ margin }) => margin};
`
const AccordionHeader = styled.div`
	padding: ${Spacings.SPACING_4B} ${Spacings.SPACING_3B};
	font-size: ${Text.MEDIUM};
	display: flex;
	cursor: pointer;
	border-bottom: ${({ open, theme }) => (open ? `1px solid ${theme.palette.background.searchgrey}` : 'none')};
	background: ${({ isCustom, theme }) => (isCustom ? '#bfd8f957' : `${theme.palette.background.blueLightest}`)};
	padding: ${({ padding }) => padding};
	&:hover {
		${({ open }) => {
			if (!open) {
				return css`
					box-shadow: 0px ${Spacings.SPACING_1B} ${Spacings.SPACING_1B} 0.125rem ${({ theme }) => theme.palette.background.blue + '17'};
				`
			}
		}}
	}
	& > span {
		flex-grow: 1;
	}
`
const AccordionBody = styled.div`
	display: flex;
	flex-direction: column;
	height: ${({ open }) => (open ? 'fit-content' : '0')};
	visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
	opacity: ${({ open }) => (open ? '1' : '0')};

	transition: all 0.15s ease-in-out;
	overflow: hidden;
	padding: ${({ open }) => (open ? Spacings.SPACING_3B : 0)};
`
const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	& > svg {
		fill: ${({ theme }) => theme.palette.text.darkblack};
	}
`

const Accordion = ({ header, children, defaultOpen, margin, padding, isCustom }) => {
	const [open, setOpen] = useState(defaultOpen)
	useEffect(() => {
		setOpen(defaultOpen)
	}, [defaultOpen])

	return (
		<AccordionComponent margin={margin}>
			<AccordionHeader onClick={() => setOpen(!open)} open={open} padding={padding} isCustom={isCustom}>
				<span>{header}</span>
				<IconWrapper>{open ? <ExpandLess fontSize='small' /> : <ExpandMore fontSize='small' />}</IconWrapper>
			</AccordionHeader>
			<AccordionBody open={open}>{children}</AccordionBody>
		</AccordionComponent>
	)
}

export default Accordion
