import { memo, useEffect, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { Text } from '@styles'
import useIntersection from './useIntersection'

const Wrapper = styled.div`
	min-width: ${({ width }) => (width ? width + 'px' : '100%')};
	max-width: ${({ width }) => (width ? width + 'px' : '100%')};
	height: ${({ height }) => (height ? `${height}px` : '100%')};
	max-height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	position: relative;
	background: ${({ noBackground, theme }) => (noBackground ? 'transparent' : theme.palette.background.white)};
	pointer-events: ${({ noPointerEvents }) => (noPointerEvents ? 'none' : 'all')};
`

const Image = styled.img`
	max-height: 100%;
	max-width: 100%;
	opacity: ${({ loaded }) => (loaded ? 1 : 0)};
	user-select: none;
`

const LoaderWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const gradientAnimation = keyframes`
    0% { background-position: -150% 0; }
    100% { background-position: 150% 0; }
`

const LoadingAnimationDiv = styled.div`
	background: red;
	height: 100%;
	width: 100%;

	background: ${({ theme }) =>
		`linear-gradient(to right, ${theme.palette.background.white} 10%, ${theme.palette.background.blueLightest} 50%, ${theme.palette.background.white} 90%)`};
	background-size: 300% 100%;
	animation-name: ${gradientAnimation};
	animation-duration: 2.5s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	animation-fill-mode: forwards;

	display: flex;
	justify-content: center;
	align-items: center;

	& > span {
		font-size: ${Text.EXTRA_SMALL};
		color: ${({ theme }) => theme.palette.text.label};
	}
`

const ImageRenderer = memo(({ src, height, width, onClick, index, noBackground, noPointerEvents, ...rest }) => {
	const isFileInstance = src instanceof File
	const [dataUrl, setDataUrl] = useState()

	const [isInView, setIsInView] = useState(false)
	const [loaded, setLoaded] = useState(false)
	const imgRef = useRef()

	useIntersection(imgRef, () => {
		setIsInView(true)
	})

	useEffect(() => {
		if (isFileInstance) {
			const reader = new FileReader()
			reader.readAsDataURL(src)
			reader.onload = () => {
				setDataUrl(reader.result)
			}
			reader.onerror = (error) => {
				console.log('Error: ', error)
				setDataUrl('')
			}
		}
	}, [src])

	const onLoadHandler = () => {
		setLoaded(true)
	}

	const clickHandler = () => {
		if (typeof onClick === 'function') {
			onClick(src, index)
		}
	}

	return (
		<Wrapper ref={imgRef} width={width} height={height} noBackground={noBackground} noPointerEvents={noPointerEvents}>
			{isInView ? (
				<>
					{!loaded ? (
						<LoaderWrapper>
							<LoadingAnimationDiv>
								<span>Loading...</span>
							</LoadingAnimationDiv>
						</LoaderWrapper>
					) : null}
					<Image loaded={loaded} src={isFileInstance ? dataUrl : src} onLoad={onLoadHandler} onClick={clickHandler} {...rest} />
				</>
			) : null}
		</Wrapper>
	)
})

export default ImageRenderer
