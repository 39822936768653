import styled, { css } from 'styled-components'
import { Spacings, Text } from '@styles'

const ToggleWrapper = styled.span`
	display: flex;
`

const ToggleOption = styled.span`
	font-size: ${Text.SMALL};
	font-weight: ${({ active }) => (active ? Text.BOLD_600 : 'normal')};
	color: ${({ active, theme }) => (active ? theme.palette.text.white : 'inherit')};
	background: ${({ active, theme }) => (active ? theme.palette.background.blue : theme.palette.background.white)};
	border: 1px solid ${({ theme }) => theme.palette.background.blue};
	cursor: ${({ active }) => (active ? 'initial' : 'pointer')};
	padding: ${Spacings.SPACING_1B} ${Spacings.SPACING_4B};
	min-width: 4rem;
	display: inline-flex;
	justify-content: center;
	user-select: none;

	transition: all 0.15s ease-in;

	&:first-of-type {
		border-top-left-radius: ${Spacings.SPACING_2B};
		border-bottom-left-radius: ${Spacings.SPACING_2B};
	}
	&:last-of-type {
		border-left: none;
		border-top-right-radius: ${Spacings.SPACING_2B};
		border-bottom-right-radius: ${Spacings.SPACING_2B};
	}

	${({ disabled }) => {
		if (disabled) {
			return css`
				pointer-events: none;
				cursor: initial;
				background: ${({ active, theme }) => (active ? theme.palette.background.border : theme.palette.background.white)};
				border-color: ${({ theme }) => theme.palette.background.border};
			`
		}
	}}
`
const Toggle = ({ options, disabled }) => {
	return (
		<ToggleWrapper>
			{options.map((_o, index) => (
				<ToggleOption key={`toggle-option-${index}`} active={_o.active} disabled={disabled} onClick={disabled ? () => {} : _o.click}>
					{_o.label}
				</ToggleOption>
			))}
		</ToggleWrapper>
	)
}

export default Toggle
