import styled, { css } from 'styled-components'
import { Devices, Spacings } from '@styles'
import Button from './Button'

const MobileButton = styled.span`
	position: ${({ notFixed }) => (notFixed ? 'initial' : 'fixed')};
	background: ${({ theme }) => theme.palette.background.blue};
	${({ disabled }) => {
		if (disabled) {
			return css`
				background: ${({ theme }) => theme.palette.background.disabled};
			`
		}
	}}
	color: ${({ theme }) => theme.palette.text.white};
	box-shadow: 0 0 ${Spacings.SPACING_4B} ${Spacings.SPACING_1B} rgb(0 0 0 / 5%);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: ${Spacings.SPACING_2B};
	bottom: ${({ bottom }) => bottom ?? '2.5rem'};
	right: ${({ right }) => right ?? '1rem'};
	cursor: pointer;
	transition: box-shadow 0.15s ease-in;

	margin: ${({ notFixed, margin }) => (notFixed ? margin : 0)};
	&:hover {
		box-shadow: 0 0 ${Spacings.SPACING_4B} ${Spacings.SPACING_2B} rgb(0 0 0 / 5%);
	}

	@media ${Devices.tablet} {
		display: none;
	}
`
const getBorderColor = ({ theme, disabled, variant, type = 'primary' }, isHover = false) => {
	const colors = theme.palette.background

	if (disabled) return colors.disabled

	if (type === 'secondary' && isHover) {
		if (variant === 'destructive') {
			return colors.red
		} else if (variant === 'success') {
			return colors.green
		} else {
			return colors.blueLight
		}
	}

	return 'transparent'
}

const DesktopButton = styled(Button)`
	display: none;
	${({ isBorder }) => {
		if (isBorder) {
			return css`
				opacity: 0.9;
				border-color: ${(props) => getBorderColor(props, true)};
				&:hover {
					opacity: unset;
				}
			`
		}
	}}

	@media ${Devices.tablet} {
		display: initial;
	}
`

const FloatingButton = ({ desktopLabel, mobileLabel, children, ...props }) => {
	return (
		<>
			<MobileButton {...props}>{mobileLabel}</MobileButton>
			<DesktopButton {...props}>{desktopLabel}</DesktopButton>
			{children}
		</>
	)
}

export default FloatingButton
