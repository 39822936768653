import { useEffect } from 'react'

const listenerCallbacks = new WeakMap()

let observer

const handleIntersections = (entries) => {
	entries.forEach((entry) => {
		if (listenerCallbacks.has(entry.target)) {
			let cb = listenerCallbacks.get(entry.target)

			if (entry.isIntersecting || entry.intersectionRatio > 0) {
				observer.unobserve(entry.target)
				listenerCallbacks.delete(entry.target)
				cb()
			}
		}
	})
}

const getIntersectionObserver = () => {
	if (!observer) {
		observer = new IntersectionObserver(handleIntersections, {
			rootMargin: '100px',
			threshold: '0.15',
		})
	}
	return observer
}

const useIntersection = (elem, callback) => {
	useEffect(() => {
		const target = elem.current
		const observer = getIntersectionObserver()
		listenerCallbacks.set(target, callback)
		observer.observe(target)

		return () => {
			listenerCallbacks.delete(target)
			observer.unobserve(target)
		}
	}, [])
}

export default useIntersection
