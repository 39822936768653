import { withTheme } from 'styled-components'

const MonitorWeightIcon = ({ height = 24, width = 24, strokeColor, theme }) => (
	<svg width={width} height={height} viewBox='0 0 24 24' fill='none'>
		<path
			d='M10 22H14C19 22 21 20 21 15V9C21 4 19 2 14 2H10C5 2 3 4 3 9V15C3 20 5 22 10 22Z'
			stroke={strokeColor ?? theme.palette.text.primary}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M17.25 8.28992C14.26 5.62992 9.74 5.62992 6.75 8.28992L8.93 11.7899C10.68 10.2299 13.32 10.2299 15.07 11.7899L17.25 8.28992Z'
			stroke={strokeColor ?? theme.palette.text.primary}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

export default withTheme(MonitorWeightIcon)
