import { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { ChevronLeft, ChevronRight, ArrowDownward, Add, Remove, Close, RotateLeft, RotateRight, DeleteOutline } from '@material-ui/icons'
import { ProgressBar, Viewer, Worker } from '@react-pdf-viewer/core'
import { zoomPlugin } from '@react-pdf-viewer/zoom'
import { rotatePlugin } from '@react-pdf-viewer/rotate'
import { getFilePlugin } from '@react-pdf-viewer/get-file'

import { ImageRenderer } from '@base'
import {
	Wrapper,
	ViewerWrapper,
	ViewerFooter,
	Tool,
	ZoomLevel,
	NavigationButton,
	CloseTool,
	ScalingArea,
	RenderingArea,
	ToolbarWrapper,
	PdfWrapper,
} from './styles'
import '@react-pdf-viewer/core/lib/styles/index.css'
import { MODAL_TYPES, strings } from '@constants'
import { toggleModal } from '@data/state/action/root'
import { useDispatch } from 'react-redux'

const PdfRenderer = ({ src, zoomPluginInstance, rotatePluginInstance, getFilePluginInstance }) => {
	const isFileInstance = src instanceof File

	const [dataUrl, setDataUrl] = useState()

	useEffect(() => {
		if (isFileInstance) {
			const reader = new FileReader()
			reader.readAsDataURL(src)
			reader.onload = () => {
				setDataUrl(reader.result)
			}
			reader.onerror = (error) => {
				console.log('Error: ', error)
				setDataUrl('')
			}
		}
	}, [src])

	if (isFileInstance && !dataUrl) return null

	return (
		<PdfWrapper>
			<Worker workerUrl='https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js'>
				<Viewer
					fileUrl={isFileInstance ? dataUrl : src}
					plugins={[zoomPluginInstance, rotatePluginInstance, getFilePluginInstance]}
					defaultScale={0.8}
					renderLoader={(percentages) => (
						<div className='progress-bar'>
							<ProgressBar progress={Math.round(percentages)} />
						</div>
					)}
				/>
			</Worker>
		</PdfWrapper>
	)
}

const SCALE_FACTOR = 0.25
const MAX_SCALE = 3
const MIN_SCALE = 0.25

const FileViewer = ({ show, files = [], onConfirm, onClose, onDelete, confirmationText = 'Confirm' }) => {
	const askConfirmation = typeof onConfirm === 'function'

	const filesCount = files?.length ?? 0

	const scalingAreaRef = useRef()
	const renderingAreaRef = useRef()
	const translation = useRef({})
	const rotation = useRef(0)
	const dispatch = useDispatch()

	const [scale, setScale] = useState(1)

	const [fileIndex, setFileIndex] = useState(0)

	const zoomIn = () => setScale((_s) => (_s >= MAX_SCALE ? _s : _s + SCALE_FACTOR))
	const zoomOut = () => setScale((_s) => (_s <= MIN_SCALE ? _s : _s - SCALE_FACTOR))

	const navigateLeft = () => setFileIndex((_i) => (_i <= 0 ? _i : _i - 1))
	const navigateRight = () => setFileIndex((_i) => (_i >= filesCount - 1 ? _i : _i + 1))

	const zoomPluginInstance = zoomPlugin({ enableShortcuts: true })
	const rotatePluginInstance = rotatePlugin()
	const getFilePluginInstance = getFilePlugin()

	const { CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance
	const { Rotate } = rotatePluginInstance
	const { Download } = getFilePluginInstance

	const isPdf = useMemo(() => {
		const file = files?.[fileIndex]

		if (file instanceof File) {
			return files?.[fileIndex].type === 'application/pdf'
		}
		return (file ?? '').split('.').reverse()[0].toLowerCase() === 'pdf'
	}, [files, fileIndex])

	useEffect(() => {
		window.addEventListener('popstate', close)

		return () => {
			window.removeEventListener('popstate', close)
		}
	}, [])

	useEffect(() => {
		const keyPressHandler = (e) => {
			if (e.keyCode === 37) {
				navigateLeft()
			} else if (e.keyCode === 39) {
				navigateRight()
			} else if (e.key === 'Escape') {
				close()
				document.removeEventListener('keydown', keyPressHandler)
			}
		}
		if (show) {
			document.addEventListener('keydown', keyPressHandler)
		}
		return () => {
			document.removeEventListener('keydown', keyPressHandler)
		}
	}, [show])

	useEffect(() => {
		reset()
	}, [fileIndex, files])

	useEffect(() => {
		if (renderingAreaRef.current) {
			translateAsPerRotation()
		}
	}, [scale])

	const reset = useCallback(() => {
		translation.current = {}
		rotation.current = 0
		setScale(1)
		if (renderingAreaRef.current) {
			renderingAreaRef.current.style['transform'] = `scale(${scale})`
		}
	}, [scale])

	const rotateLeft = () => {
		if (renderingAreaRef.current) {
			setScale(1)
			translation.current = {}
			rotation.current -= 90
			renderingAreaRef.current.style['transform'] = `scale(${scale}) rotate(${rotation.current ?? 0}deg)`
		}
	}
	const rotateRight = () => {
		if (renderingAreaRef.current) {
			setScale(1)
			translation.current = {}
			rotation.current += 90
			renderingAreaRef.current.style['transform'] = `scale(${scale}) rotate(${rotation.current ?? 0}deg)`
		}
	}

	const pointerDownHandler = (e) => {
		if (!renderingAreaRef.current) return

		const { clientX: initialX, clientY: initialY } = e
		const { x, y } = translation.current

		//remove transition
		renderingAreaRef.current.style['transition'] = 'none'

		const pointerMoveHandler = (moveEvent) => {
			requestAnimationFrame(() => {
				const { clientX, clientY } = moveEvent
				const xDelta = clientX - initialX + (x ?? 0)
				const yDelta = clientY - initialY + (y ?? 0)

				translation.current = {
					x: xDelta,
					y: yDelta,
				}

				translateAsPerRotation()
			})
		}

		const pointerUpHandler = (e) => {
			renderingAreaRef.current.removeEventListener('pointermove', pointerMoveHandler)
			// add transition
			renderingAreaRef.current.style['transition'] = 'transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
		}

		renderingAreaRef.current.addEventListener('pointerup', pointerUpHandler)
		renderingAreaRef.current.addEventListener('pointerleave', pointerUpHandler)
		renderingAreaRef.current.addEventListener('pointermove', pointerMoveHandler)
	}

	const translateAsPerRotation = () => {
		const { x = 0, y = 0 } = translation.current
		let style = `rotate(${rotation.current ?? 0}deg) scale(${scale}) `
		const mod = Math.abs(rotation.current / 90) % 4
		if (mod === 0 || mod === 4) {
			style = `${style} translate(${x / scale + 'px'}, ${y / scale + 'px'})`
		} else if (mod === 1) {
			style = `${style} translate(${(Math.sign(rotation.current) * y) / scale + 'px'}, ${(Math.sign(rotation.current) * -x) / scale + 'px'})`
		} else if (mod === 2) {
			style = `${style} translate(${-x / scale + 'px'}, ${-y / scale + 'px'})`
		} else if (mod === 3) {
			style = `${style} translate(${(Math.sign(rotation.current) * -y) / scale + 'px'}, ${(Math.sign(rotation.current) * x) / scale + 'px'})`
		}

		renderingAreaRef.current.style['transform'] = style
	}

	const downloadImage = () => {
		const file = files[fileIndex]
		const a = document.createElement('a')
		a.href = file
		a.download = file.split('/').reverse()[0]
		document.body.appendChild(a)
		a.click()
		document.body.removeChild(a)
	}

	const close = useCallback(() => {
		setFileIndex(0)
		onClose()
	}, [])

	const renderFileView = () => {
		const file = files[fileIndex]
		if (isPdf) {
			return (
				<PdfRenderer
					src={file}
					zoomPluginInstance={zoomPluginInstance}
					rotatePluginInstance={rotatePluginInstance}
					getFilePluginInstance={getFilePluginInstance}
				/>
			)
		}

		return (
			<ScalingArea ref={(_ref) => (scalingAreaRef.current = _ref)}>
				<RenderingArea scale={scale} onPointerDown={pointerDownHandler} ref={(_ref) => (renderingAreaRef.current = _ref)}>
					<ImageRenderer src={file} noBackground noPointerEvents />
				</RenderingArea>
			</ScalingArea>
		)
	}
	const deleteInvoiceHandler = () => () => {
		const file = files[fileIndex].split('=')
		dispatch(
			toggleModal(true, MODAL_TYPES.CONFIRMATION, {
				overlayClickable: true,
				title: strings('msg_are_you_sure_want_to_delete_doc'),
				confirmAction: (actionType) => {
					actionType && onDelete(file[1])
					dispatch(toggleModal(false))
					if (filesCount > 1 && fileIndex > 1) setFileIndex(fileIndex - 1)
					else if (filesCount > 1) setFileIndex(0)
					else onClose()
				},
			})
		)
	}

	const renderImagesToolbar = () =>
		!isPdf && (
			<ToolbarWrapper>
				<ToolItem disabled={scale >= MAX_SCALE} onClick={zoomIn} icon={<Add />} />
				<ZoomLevel>{scale * 100}%</ZoomLevel>
				<ToolItem disabled={scale <= MIN_SCALE} onClick={zoomOut} icon={<Remove />} />
				<ToolItem onClick={rotateLeft} icon={<RotateLeft />} />
				<ToolItem onClick={rotateRight} icon={<RotateRight />} />
				{!askConfirmation && <ToolItem onClick={downloadImage} icon={<ArrowDownward />} />}
				{askConfirmation && <ToolItem isText onClick={onConfirm} icon={confirmationText} />}
				{onDelete && <ToolItem onClick={deleteInvoiceHandler()} icon={<DeleteOutline />} />}
			</ToolbarWrapper>
		)

	const renderPdfToolbar = () =>
		isPdf && (
			<ToolbarWrapper>
				<ZoomIn>{(props) => <ToolItem onClick={props.onClick} icon={<Add />} />}</ZoomIn>
				<CurrentScale>{(props) => <ZoomLevel>{`${Math.round(props.scale * 100)}%`}</ZoomLevel>}</CurrentScale>
				<ZoomOut>{(props) => <ToolItem onClick={props.onClick} icon={<Remove />} />}</ZoomOut>
				<Rotate direction='Backward'>{(props) => <ToolItem onClick={props.onClick} icon={<RotateLeft />} />}</Rotate>
				<Rotate direction='Forward'>{(props) => <ToolItem onClick={props.onClick} icon={<RotateRight />} />}</Rotate>
				{!askConfirmation && <Download>{(props) => <ToolItem onClick={props.onClick} icon={<ArrowDownward />} />}</Download>}
				{askConfirmation && <ToolItem isText onClick={onConfirm} icon={confirmationText} />}
				{onDelete && <ToolItem onClick={deleteInvoiceHandler()} icon={<DeleteOutline />} />}
			</ToolbarWrapper>
		)

	if (!show || files?.length === 0) return null

	return (
		<Wrapper>
			<CloseTool onClick={close}>
				<Close />
			</CloseTool>
			<ViewerWrapper>{renderFileView()}</ViewerWrapper>
			<ViewerFooter>
				<NavigationButton disabled={fileIndex <= 0} isLeft onClick={navigateLeft}>
					<ChevronLeft />
				</NavigationButton>
				{renderImagesToolbar()}
				{renderPdfToolbar()}
				<NavigationButton disabled={fileIndex >= filesCount - 1} onClick={navigateRight}>
					<ChevronRight />
				</NavigationButton>
			</ViewerFooter>
		</Wrapper>
	)
}

const ToolItem = ({ disabled, onClick, icon, ...rest }) => {
	return (
		<Tool disabled={disabled} onClick={onClick} {...rest}>
			{icon}
		</Tool>
	)
}

export default FileViewer
