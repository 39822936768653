import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { Devices, Spacings, Text } from '@styles'
import { showDateFormat } from '@utils'
import { showLoader } from '@data/state/action'

const Timeline = styled.div`
	position: relative;
	width: 100%;
	margin: 0 auto;
	padding: 0px ${Spacings.SPACING_3B};
	@media ${Devices.mobile} {
		padding: ${Spacings.SPACING_4B} 0;
	}
	&:after {
		content: '';
		position: absolute;
		width: 2px;
		background: ${({ theme }) => theme.palette.text.green};
		top: 0;
		bottom: 0;
		left: 28%;
		margin-bottom: ${Spacings.SPACING_12B};
	}

	@media ${Devices.mobile} {
		&:after {
			left: 50%;
			margin-bottom: ${Spacings.SPACING_13B};
		}
	}
`
const ContainerWapper = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	align-items: center;
	${({ isLeft }) => {
		if (isLeft) {
			return css`
				flex-direction: row-reverse;
				@media ${Devices.mobile} {
					flex-direction: inherit;
				}
			`
		} else {
			return css`
				flex-direction: row-reverse;
			`
		}
	}}

	&:after {
		content: '';
		position: absolute;
		width: 10px;
		height: 10px;
		top: calc(50% - ${Spacings.SPACING_2B});

		background: ${({ theme, status }) => (status === 'ORDER_COMPLETED' ? theme.palette.text.green : '#ffffff')};
		border: 2px solid ${({ theme }) => theme.palette.text.green};
		border-radius: ${Spacings.SPACING_2B};
		z-index: 1;
		left: 25%;
		@media ${Devices.mobile} {
			left: 49%;
		}
	}
	&:before {
		content: '';
		position: absolute;
		height: 2px;
		top: calc(50% - 1px);
		width: ${Spacings.SPACING_8B};
		@media ${Devices.mobile} {
			width: ${Spacings.SPACING_12B};
		}
		${({ isLeft }) => {
			if (isLeft) {
				return css`
					left: 6rem;
					@media ${Devices.mobile} {
						left: 13.5rem;
					}
					@media ${Devices.tablet} {
						left: 14.5rem;
					}
					@media ${Devices.laptop} {
						left: 13.5rem;
					}
					@media ${Devices.desktop} {
						left: 13.5rem;
					}
				`
			}
			return css`
				left: 6rem;
				@media ${Devices.mobile} {
					left: 13.5rem;
				}
				@media ${Devices.tablet} {
					left: 17.5rem;
				}
				@media ${Devices.laptop} {
					left: 16.5rem;
				}
				@media ${Devices.desktop} {
					left: 16.5rem;
				}
			`
		}}
		background: ${({ theme }) => theme.palette.text.green};
	}
`
const Container = styled.div`
	background: inherit;
	width: 100%;
	${({ isLeft }) => {
		if (isLeft) {
			return css`
				margin-right: 0px;
				flex: 2;

				@media ${Devices.mobile} {
					flex: 1;
					margin-right: ${Spacings.SPACING_16B};
				}
			`
		}
		return css`
			margin-left: 0px;
			@media ${Devices.mobile} {
				flex: 1;
				margin-left: ${Spacings.SPACING_16B};
			}
		`
	}}
`
const ContentWapper = styled.div`
	width: 100%;
	padding: 0px ${Spacings.SPACING_1B};
	position: relative;
	background: ${({ theme }) => theme.palette.background.white};
	box-shadow: ${({ theme, isPaymentType }) =>
		isPaymentType ? `0 0.25rem 1.25rem 0 ${theme.palette.background.redLight}` : `0 0.25rem 1.25rem 0 rgb(0 0 0 / 15%)`};
	margin: 10px;
	@media ${Devices.mobile} {
		margin: unset;
	}
	${({ isLeft }) => {
		if (isLeft) {
			return css`
				border-radius: ${Spacings.SPACING_32B} 0 0 ${Spacings.SPACING_32B};
				@media ${Devices.mobile} {
					border-radius: 0 ${Spacings.SPACING_32B} ${Spacings.SPACING_32B} 0;
				}
			`
		}
		return css`
			border-radius: ${Spacings.SPACING_32B} 0 0 ${Spacings.SPACING_32B};
			@media ${Devices.mobile} {
				border-radius: ${Spacings.SPACING_32B} 0 0 ${Spacings.SPACING_32B};
			}
		`
	}};

	${({ isClickable }) => {
		if (isClickable) {
			return css`
				&:hover {
					cursor: pointer;
				}
			`
		}
	}}
`

const ContentItemWapper = styled.div`
	display: flex;
	flex-direction: column;

	${({ isLeft }) => {
		if (isLeft) {
			return css`
				margin-left: ${Spacings.SPACING_9B};
				@media ${Devices.mobile} {
					margin-left: unset;
				}
			`
		}
	}};
`

const ContentItem = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: ${Spacings.SPACING_1B} 0px;

	justify-content: flex-end;
	${({ isLeft }) => {
		if (isLeft) {
			return css`
				@media ${Devices.mobile} {
					justify-content: flex-start;
				}
			`
		}
		return css`
			justify-content: flex-end;
		`
	}};
`

const Heading = styled.div`
	color: ${({ theme }) => theme.palette.text.darkblack};
	font-weight: ${Text.BOLD_600};
	font-size: ${Text.SMALL};
	display: flex;
	overflow-wrap: anywhere;
	padding-top: 3px;
	${({ isLeft }) => {
		if (isLeft) {
			return css`
				justify-content: flex-end;
				@media ${Devices.mobile} {
					justify-content: flex-start;
				}
			`
		}
		return css`
			justify-content: flex-end;
		`
	}};
`
const Content = styled.div`
	font-size: ${Text.SMALL};
	color: ${({ theme }) => theme.palette.text.greydark};
	${({ isLeft }) => {
		if (isLeft) {
			return css`
				margin: 0px 0px 0px 0px;
				@media ${Devices.mobile} {
					margin: 0px ${Spacings.SPACING_8B} 0px 0px;
				}
			`
		}
		return css`
			margin: 0px 0px 0px ${Spacings.SPACING_8B};
		`
	}}
`

const DateWapper = styled.div`
	display: flex;
	top: calc(50% - 10px);
	text-align: center;
	font-weight: ${Text.BOLD_600};
	font-size: ${Text.MEDIUM};
	text-transform: uppercase;
	letter-spacing: 1px;
	z-index: 1;
	width: 100%;
	flex-shrink: 2;
	color: ${({ theme }) => theme.palette.text.greydark};
	@media ${Devices.mobile} {
		flex-shrink: unset;
	}
	${({ isLeft }) => {
		if (isLeft) {
			return css`
				justify-content: flex-start;
				flex: 1;
			`
		}
		return css`
			justify-content: flex-start;
			@media ${Devices.mobile} {
				justify-content: flex-end;
				flex: 1;
			}
		`
	}}
`

const IconWapper = styled.div`
	position: absolute;
	border: 2px solid ${({ theme }) => theme.palette.text.green};
	border-radius: ${Spacings.SPACING_4B};
	text-align: center;
	color: ${({ theme }) => theme.palette.text.green};
	height: 1.4rem;
	width: 1.4rem;
	top: calc(50% - 7px);
	@media ${Devices.mobile} {
		top: calc(50% - 10px);
	}
	${({ isLeft }) => {
		if (isLeft) {
			return css`
				right: 0%;
				left: 3%;
				@media ${Devices.mobile} {
					right: 4%;
					left: unset;
				}
			`
		}
		return css`
			left: 3%;
			@media ${Devices.mobile} {
				left: 3%;
			}
		`
	}}

	& > svg {
		font-size: ${Text.LARGE};
		height: 1rem;
		width: 1rem;
		border-radius: ${Spacings.SPACING_4B};
	}
`

const TimeLine = ({ data, isCollection = false, clickhandler = null }) => {
	const [currentTime, setCurrentTime] = useState()

	useEffect(() => {
		const createdAt = new Date(data?.slice(-1)[0]?.created_at + 300000).getTime()
		const dateNow = Date.now()
		if (createdAt > dateNow) {
			const diff = createdAt - dateNow + new Date().getTimezoneOffset() * 60000
			setCurrentTime(new Date(diff).getMinutes() + ':' + new Date(diff).getSeconds())
		} else {
			setCurrentTime()
		}
		const interval = setInterval(() => {
			const dateNow = Date.now()
			if (createdAt > dateNow) {
				const diff = createdAt - dateNow + new Date().getTimezoneOffset() * 60000
				setCurrentTime(new Date(diff).getMinutes() + ':' + new Date(diff).getSeconds())
			} else {
				setCurrentTime()
			}
		}, 1000)
		return () => {
			window.clearInterval(interval)
		}
	}, [data])

	const TimelineElementCollection = (item, index) => {
		return (
			<ContainerWapper key={index} isLeft={index % 2 === 0} status={item?.action}>
				<Container isLeft={index % 2 === 0}>
					<ContentWapper
						isClickable
						onClick={() => {
							let isEditRemark = currentTime != null && index === data.length - 1 && index !== 0
							clickhandler(item, index, isEditRemark)
						}}
						isLeft={index % 2 === 0}
						isPaymentType={item?.journeyState?.paymentType}
					>
						<Heading isLeft={index % 2 === 0}>{item?.action}</Heading>

						<ContentItemWapper isLeft={index % 2 === 0}>
							<ContentItem isLeft={index % 2 === 0}>
								<IconWapper isLeft={index % 2 === 0}>{item?.journeyState?.icon}</IconWapper>

								<Content isLeft={index % 2 === 0}>{item?.journeyState?.text}</Content>
							</ContentItem>
						</ContentItemWapper>
					</ContentWapper>
				</Container>
				<DateWapper isLeft={index % 2 === 0}>
					{index !== 0 && index === data.length - 1 && currentTime != null ? currentTime : showDateFormat(new Date(item?.date))}
				</DateWapper>
			</ContainerWapper>
		)
	}

	const TimelineElementBasic = (item, index) => {
		return (
			<ContainerWapper key={index} isLeft={index % 2 === 0} status={item?.action}>
				<Container isLeft={index % 2 === 0}>
					<ContentWapper isLeft={index % 2 === 0}>
						<Heading isLeft={index % 2 === 0}>{item?.action}</Heading>

						<ContentItemWapper isLeft={index % 2 === 0}>
							<ContentItem isLeft={index % 2 === 0}>
								<IconWapper isLeft={index % 2 === 0}>{item?.journeyState?.icon}</IconWapper>

								<Content isLeft={index % 2 === 0}>{item?.journeyState?.text}</Content>
							</ContentItem>
						</ContentItemWapper>
					</ContentWapper>
				</Container>{' '}
				<DateWapper isLeft={index % 2 === 0}>{showDateFormat(new Date(item?.date))}</DateWapper>
			</ContainerWapper>
		)
	}
	return (
		<Timeline>
			{data?.map((item, index) => (
				<>{isCollection ? TimelineElementCollection(item, index) : TimelineElementBasic(item, index)}</>
			))}
		</Timeline>
	)
}

export default TimeLine
