import { Text } from '@styles'
import React from 'react'
import styled from 'styled-components'
import {
  CheckCircle as CheckCircleIcon
} from '@material-ui/icons';

const CircleBackground = styled.circle`
	fill: none;
	stroke: #ddd;
`
const CircleProgress = styled.circle`
	fill: none;
	stroke: ${({theme}) => theme.palette.text.blue};
	stroke-linecap: round;
	stroke-linejoin: round;
`

const CircleText = styled.text`
	font-size: ${Text.SMALL};
	font-weight: bold;
	fill: ${({theme}) => theme.palette.text.blue};
`

const CircularProgressBar = ({sqSize, strokeWidth, percentage }) => { 
  const radius = (sqSize - strokeWidth) / 2;
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  const dashArray = radius * Math.PI * 2;
  const percentageValue = percentage || 0;
  const dashOffset = dashArray - dashArray * percentageValue / 100;
  
  return (
    <svg
        width={sqSize}
        height={sqSize}
        viewBox={viewBox}>
        <CircleBackground
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`} />
        <CircleProgress
          className="circle-progress"
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          // Start progress marker at 12 O'Clock
          transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset
          }} />
      {percentageValue !== '100' && <CircleText
          x="50%"
          y="50%"
          dy=".3em"
          textAnchor="middle">
          {`${percentageValue || 0}`}
        </CircleText>
      }
      {percentageValue === '100' && <CheckCircleIcon /> }
    </svg>
  );
}

CircularProgressBar.defaultProps = {
  sqSize: 20,
  strokeWidth: 2
};

export default CircularProgressBar
