import React from 'react'
import styled from 'styled-components'
import { Devices, Spacings, Text } from '@styles'
import { H6 } from '@base'

const MangerName = styled.div`
	display: flex;
	flex-direction: column;
	font-size: ${Text.SMALL};
	color: ${({ theme, isPrimary, isPrimarySupply }) => {
		const primaryColor = isPrimary ? theme.palette.background.messageRed : theme.palette.background.darkblack
		return isPrimarySupply ? theme.palette.background.greenDark : primaryColor
	}};
	margin: ${Spacings.SPACING_1B} ${Spacings.SPACING_1B} 0 0;
	line-height: 1.6;
	font-weight: 600;

	&:last-of-type {
		margin: ${Spacings.SPACING_1B} 0 0 0;
	}
`

const ManagerNamesWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`

const Heading = styled(H6)`
	color: ${({ theme }) => theme.palette.text.label};
	font-weight: ${Text.BOLD_600};
	@media ${Devices.tablet} {
		margin-right: ${Spacings.SPACING_2B};
	}
`

const AssignedRelationShipManager = ({ data }) => {
	if (!data?.relationship_manager)
		return (
			<Wrapper>
				<Heading>Not assigned yet</Heading>
			</Wrapper>
		)

	return (
		<Wrapper>
			<Heading>Enquiry assigned to</Heading>
			<ManagerNamesWrapper>
				{data?.relationship_manager.map((_employee, index) => (
					<MangerName
						key={`relationship_manager_${_employee.user_id}`}
						isPrimary={_employee?.is_primary}
						isPrimarySupply={_employee?.is_primary_supply}
					>
						<span>
							{_employee.full_name ?? _employee.mobile_number}
							{index < data?.relationship_manager.length - 1 ? ',' : ''}
						</span>
					</MangerName>
				))}
			</ManagerNamesWrapper>
		</Wrapper>
	)
}

export default AssignedRelationShipManager
