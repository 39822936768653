import React, { useCallback, useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { Devices, Spacings, Sizes, Text } from '@styles'
import { ChevronLeft, ChevronRight, Equalizer } from '@material-ui/icons'
import Message from './TableUtils/message'
import EmptyState from '../EmptyState'
import { H6 } from '@base'
import { getPlatform } from '@utils'

const TableWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: ${({ fullHeight }) => (fullHeight ? 1 : 0)};
	background: ${({ theme }) => theme.palette.background.white};
	border-radius: ${({ noRoundBorder }) => (noRoundBorder ? '0' : Spacings.SPACING_2B)};
	font-size: ${Text.SMALL};
	color: ${({ theme }) => theme.palette.text.darkblack};
	min-height: ${({ noScroll }) => (noScroll ? 'auto' : '180px')};
`

const ContentWrapper = styled.div`
	overflow-y: scroll;
	flex-grow: 1;
	height: ${({ fullHeight }) => (fullHeight ? '1rem' : 'fit-content')};
	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}
	/* Hide scrollbar for IE, Edge and Firefox */
	& {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
`

const InnerRowWrapper = styled.div`
	display: flex;
	cursor: ${({ rowClickable }) => (rowClickable ? 'pointer' : 'initial')};

	padding: 0 ${Spacings.SPACING_3B};

	transition: all 0.2s ease-in-out;
	border: 1px solid transparent;
	border-bottom: 1px solid ${({ theme }) => theme.palette.background.searchgrey};
	${({ rowClickable }) => {
		if (rowClickable) {
			return css`
				&:hover {
					background: ${({ theme }) => theme.palette.background.blueLightest};
					// border-color: ${({ theme }) => theme.palette.background.blue};
					border-color: transparent;
				}
			`
		}
	}}
`

const BaseRow = styled.div`
	display: flex;
	background: ${({ theme }) => theme.palette.background.white};

	@media ${Devices.tablet} {
		&:first-child {
			border-top-left-radius: ${({ noRoundBorder }) => (noRoundBorder ? '0' : Spacings.SPACING_2B)};
			border-top-right-radius: ${({ noRoundBorder }) => (noRoundBorder ? '0' : Spacings.SPACING_2B)};
		}

		&:last-child {
			border-bottom-left-radius: ${({ noRoundBorder }) => (noRoundBorder ? '0' : Spacings.SPACING_2B)};
			border-bottom-right-radius: ${({ noRoundBorder }) => (noRoundBorder ? '0' : Spacings.SPACING_2B)};
		}
	}
`

const Row = styled(BaseRow)`
	flex-direction: column;
	position: relative;
	${({ loading }) => {
		if (loading) {
			return css`
				padding: 1rem 1rem;
			`
		}
	}}
`

const gradientAnimation = keyframes`
    0% { background-position: -250px 0; }
    100% { background-position: 250px 0; }
`

const LoadingAnimationDiv = styled.div`
	background: red;
	min-height: 1.125rem;

	background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
	background-size: 500px 100px;
	animation-name: ${gradientAnimation};
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	animation-fill-mode: forwards;
`

const getAlignment = ({ align }) => {
	if (align === 'right') return 'flex-end'
	if (align === 'center') return 'center'
	return 'flex-start'
}

const Cell = styled.div`
	width: ${({ width }) => width}%;
	// white-space: nowrap;
	overflow: ${({ overflow }) => overflow ?? 'hidden'};
	text-overflow: ellipsis;
	padding: ${Spacings.SPACING_3B} ${Spacings.SPACING_1B};
	display: ${({ mobile }) => (mobile ? 'flex' : 'none')};
	text-align: ${({ align }) => align || 'left'};

	align-items: ${getAlignment};
	flex-direction: column;
	justify-content: center;
	@media ${Devices.tablet} {
		display: flex;
	}
`
const HeaderRow = styled(BaseRow)`
	background: ${({ theme }) => theme.palette.background.white};
	border-bottom: 1px solid ${({ theme }) => theme.palette.background.blueLight};
	padding: 0 ${Spacings.SPACING_3B};
	user-select: none;
`
const HeaderCell = styled(Cell)`
	padding: ${Spacings.SPACING_4B} ${Spacings.SPACING_1B};
	font-weight: ${Text.BOLD_600};
`

const FooterRow = styled(BaseRow)`
	background: ${({ theme }) => theme.palette.background.white};
	border-top: 1px solid ${({ theme }) => theme.palette.background.blueLight};
	user-select: none;
	padding: ${Spacings.SPACING_3B} ${Spacings.SPACING_4B};
	display: flex;
	align-items: center;
	justify-content: center;
	position: sticky;
	bottom: 0;
	// margin-top: auto;

	box-shadow: 0 0 ${Spacings.SPACING_5B} 0.125rem rgb(0 0 0 / 5%);
	@media ${Devices.tablet} {
		justify-content: flex-end;
		box-shadow: none;
	}
`

const ItemsShown = styled.span`
	margin-right: 1rem;
`
const PaginationButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`
const PaginateButton = styled.div`
	height: 2rem;
	width: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	transition: all 0.2s ease-in-out;
	cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
	color: ${({ disabled, theme }) => (disabled ? theme.palette.text.disabled : '')};
	font-size: 1rem;

	&:first-of-type {
		margin-right: 1rem;
	}
	&:hover {
		background: ${({ theme, disabled }) => (disabled ? '' : theme.palette.background.blueLightest)};
	}
`

const EmptyTableState = styled(H6)`
	display: flex;
	flex-grow: 1;
	justify-content: center;
	align-items: center;
	min-height: 5rem;

	color: ${({ theme }) => theme.palette.text.label};
	font-weight: ${Text.BOLD_600};
	line-height: ${Text.LINE_HEIGHT_MAX};
`

const Table = ({
	id = '',
	data,
	columns,
	loading,
	rowsToDisplay,
	totalItemCount,
	page,
	onPageChange,
	onRowClick,
	fullHeight,
	hideFooter,
	rowClickable = true,
	noShadow,
	noRoundBorder,
	emptyState,
	noScroll,
	...props
}) => {
	const [cellWidth, setCellWidth] = useState(1)
	const [message, setMessage] = useState({})
	const usePlatform = getPlatform()

	const calculateWidth = useCallback(() => {
		let _columns = columns
		//for columns that we need for export only
		_columns = _columns.filter((col) => col?.exportOnly !== true)
		if (window.innerWidth < Sizes.tablet) {
			_columns = columns.filter((col) => col?.mobile)
		}
		const meta = _columns.reduce(
			(acc, item) => {
				if (item?.cellWidth) {
					return {
						widthLeft: acc.widthLeft - item?.cellWidth,
						count: acc.count - 1,
					}
				}
				return acc
			},
			{ widthLeft: 100, count: _columns.length }
		)
		setCellWidth(meta.widthLeft / meta.count)
	}, [columns])

	useEffect(() => {
		calculateWidth()
		const resizeHandler = () => {
			calculateWidth()
		}
		window.addEventListener('resize', resizeHandler)
		return () => {
			window.removeEventListener('resize', resizeHandler)
		}
	}, [columns])

	const pageChangeHandler = (flag, disabled) => {
		if (disabled) return
		onPageChange(flag)
		setMessage({})
	}

	const rowClickHandler = (rowData, rowIndex) => {
		if (rowClickable) {
			onRowClick(rowData)
		}
	}

	const renderTableHeader = () => {
		return (
			<HeaderRow noRoundBorder={noRoundBorder}>
				{columns
					.filter((col) => col?.exportOnly !== true)
					.map((item, index) => {
						return (
							<HeaderCell mobile={item?.mobile} align={item?.align} width={item?.cellWidth ?? cellWidth} key={`header-cell-${index}`}>
								{item?.label}
							</HeaderCell>
						)
					})}
			</HeaderRow>
		)
	}

	const renderTableRows = () => {
		if (!loading && data && data.length) {
			return data?.map((rowData, rowIndex) => (
				<Row key={`row-r${rowIndex}`} onClick={() => rowClickHandler(rowData, rowIndex)} noRoundBorder={noRoundBorder}>
					<InnerRowWrapper rowClickable={rowClickable}>
						{columns
							.filter((col) => col?.exportOnly !== true)
							.map((item, columnIndex) => {
								let cellContent
								if (item.key) {
									if (typeof item.key === 'function') {
										cellContent = item.key(rowData)
									} else if (typeof item.key === 'string') {
										cellContent = rowData[item.key]
									}
								}

								if (item.renderer && typeof item.renderer === 'function') {
									if (item.message) {
										const setMessageHandler = (e, text, show = true) => {
											e.stopPropagation()
											setMessage({
												id: `row-r${rowIndex}`,
												show: true,
												text,
											})
										}
										cellContent = item.renderer(cellContent, setMessageHandler)
									} else {
										cellContent = item.renderer(cellContent)
									}
								}

								if (item.default && !cellContent) {
									cellContent = item.default
								}

								return (
									<Cell
										mobile={item?.mobile}
										align={item?.align}
										width={item?.cellWidth ?? cellWidth}
										overflow={item?.overflow ?? 'hidden'}
										key={`cell-r${rowIndex}-c${columnIndex}`}
									>
										{typeof cellContent === 'boolean' ? '' + cellContent : cellContent}
									</Cell>
								)
							})}
					</InnerRowWrapper>
					<Message show={message?.show && message?.id === `row-r${rowIndex}`} setShow={setMessage} text={message?.text} />
				</Row>
			))
		}
	}

	const renderEmptyState = () => {
		if (!loading && (!data || !data.length)) {
			if (fullHeight) {
				return (
					<EmptyState
						icon={emptyState?.icon || <Equalizer />}
						buttonHandler={emptyState?.buttonHandler}
						noAction={emptyState?.noAction ?? true}
						buttonText={emptyState?.buttonText}
						text={emptyState?.text || 'No data found'}
					/>
				)
			}
			return <EmptyTableState>No data found</EmptyTableState>
		}
	}

	const renderLoadingView = () => {
		if (loading) {
			const dummyData = [...Array(rowsToDisplay ?? 10).keys()]
			return dummyData.map((_, index) => (
				<Row loading key={`loading-row-${index}`} noRoundBorder={noRoundBorder}>
					<LoadingAnimationDiv />
				</Row>
			))
		}
	}

	const renderPaginationFooter = () => {
		const start = data && data.length ? page * rowsToDisplay + 1 : 0
		const end = Math.min(page * rowsToDisplay + rowsToDisplay, totalItemCount)
		const isLeftButtonDisabled = page === 0
		const isRightButtonDisabled = (page + 1) * rowsToDisplay >= totalItemCount
		return (
			!hideFooter && (
				<FooterRow noRoundBorder={noRoundBorder}>
					<ItemsShown>
						{start} - {end} of {totalItemCount}
					</ItemsShown>
					<PaginationButtonWrapper>
						<PaginateButton role='button' disabled={isLeftButtonDisabled} onClick={(_) => pageChangeHandler(false, isLeftButtonDisabled)}>
							<ChevronLeft />
						</PaginateButton>
						<PaginateButton
							role='button'
							disabled={isRightButtonDisabled}
							onClick={(_) => pageChangeHandler(true, isRightButtonDisabled)}
						>
							<ChevronRight />
						</PaginateButton>
					</PaginationButtonWrapper>
				</FooterRow>
			)
		)
	}

	const renderContent = () => {
		if (!loading && (!data || !data.length)) {
			return renderEmptyState()
		}

		const tableRowsWithScrolls = () => {
			if (noScroll) {
				return (
					<>
						{renderLoadingView()}
						{renderTableRows()}
					</>
				)
			}
			if (usePlatform !== 'Mac' || usePlatform !== 'iOS') {
				return (
					<Scrollbars
						renderView={(props) => (
							<div
								{...props}
								style={{ ...props.style, overflowX: 'hidden', scrollbarColor: 'transparent transparent', marginBottom: '0' }}
							/>
						)}
						autoHide
					>
						{renderLoadingView()}
						{renderTableRows()}
					</Scrollbars>
				)
			} else {
				return (
					<>
						{renderLoadingView()}
						{renderTableRows()}
					</>
				)
			}
		}

		return <ContentWrapper fullHeight={fullHeight}>{tableRowsWithScrolls()}</ContentWrapper>
	}

	return (
		<TableWrapper noScroll={noScroll} fullHeight={fullHeight} noShadow={noShadow} noRoundBorder={noRoundBorder} id={id} {...props}>
			{renderTableHeader()}
			{renderContent()}
			{renderPaginationFooter()}
		</TableWrapper>
	)
}

export default Table
