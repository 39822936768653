import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { MoreHoriz } from '@material-ui/icons'
import { useEffect, useRef, useState } from 'react'
import { Spacings } from '@styles'

const getPosition = ({ horizontal, vertical }) => {
	let position = ''
	position =
		position +
		`
			${horizontal}: 0;
		`
	if (vertical === 'top') {
		position =
			position +
			`
				bottom: calc(100% + ${Spacings.SPACING_2B})
			`
	}
	if (vertical === 'bottom') {
		position =
			position +
			`
				top: calc(100% + ${Spacings.SPACING_2B});
			`
	}
	return css`
		${position}
	`
}

const Wrapper = styled.div`
	display: flex;
	position: relative;
`
const Anchor = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`
const PopupWrapper = styled.div`
	padding: ${Spacings.SPACING_4B};
	border-radius: ${Spacings.SPACING_2B};
	background: ${({ theme }) => theme.palette.background.white};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	position: absolute;
	${getPosition}
`

const Popup = ({ anchorContent, popupContent, position = { horizontal: 'right', vertical: 'top' } }) => {
	const elem = useRef()
	const [show, setShow] = useState(false)

	const showFileViewer = useSelector((state) => state.getIn(['root', 'showFileViewer']))

	useEffect(() => {
		const handler = (e) => {
			if ((elem.current && elem.current.contains(e.target)) || showFileViewer) {
				return
			}
			setShow(false)
		}
		if (show) {
			window.addEventListener('mousedown', handler)
		}

		return () => {
			window.removeEventListener('mousedown', handler)
		}
	}, [show, showFileViewer])

	return (
		<Wrapper ref={(_ref) => (elem.current = _ref)}>
			<Anchor onClick={() => setShow(!show)}>{anchorContent || <MoreHoriz fontSize='medium' />}</Anchor>
			{show && <PopupWrapper {...position}>{popupContent}</PopupWrapper>}
		</Wrapper>
	)
}

export default Popup
