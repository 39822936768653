import { useEffect, useState, useRef } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { CalendarToday } from '@material-ui/icons'
import styled, { css } from 'styled-components'

import { showDateFormat, convertLongDate } from '@utils'
import { Input } from '@base'
import { Devices, Spacings, Text } from '@styles'

const DatePickerInput = styled(Input)`
	caret-color: transparent;
	cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
`
const Wrapper = styled.div`
	position: relative;
	height: fit-content;
`
const MobileOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	transition: all 100ms cubic-bezier(0, 0, 0.2, 1);
	z-index: 99;
	background: ${({ theme }) => theme.palette.background.light};
	visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
	width: 100vw;
	height: 100vh;

	@media ${Devices.mobile} {
		display: none;
	}
`

const getPosition = ({ position }) => {
	switch (position) {
		case 'top':
			return css`
				bottom: 100%;
				left: 0;
			`
		case 'bottom':
		default:
			return css`
				top: 100%;
				left: 0;
			`
	}
}
const getTriangleStyles = ({ position }) => {
	switch (position) {
		case 'top':
			return css`
				left: ${Spacings.SPACING_5B};
				bottom: -${Spacings.SPACING_2B};
				border-left: ${Spacings.SPACING_3B} solid transparent;
				border-right: ${Spacings.SPACING_3B} solid transparent;
				border-top: ${Spacings.SPACING_2B} solid ${({ theme }) => theme.palette.background.blue};
			`
		case 'bottom':
		default:
			return css`
				left: ${Spacings.SPACING_5B};
				top: -${Spacings.SPACING_2B};
				border-left: ${Spacings.SPACING_3B} solid transparent;
				border-right: ${Spacings.SPACING_3B} solid transparent;
				border-bottom: ${Spacings.SPACING_2B} solid ${({ theme }) => theme.palette.background.blue};
			`
	}
}

const InlineDatePickerWrapper = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	z-index 100;

	& .react-datepicker {
		width: 100%;

		box-shadow: 0 0 ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
		border: none;


		.react-datepicker__navigation-icon::before {
			border-color: ${({ theme }) => theme.palette.text.white};
			border-style: solid;
			border-width: ${Spacings.SPACING_2} ${Spacings.SPACING_2} 0 0;
			content: '';
			display: block;
			position: absolute;
			top: ${Spacings.SPACING_4B};
			height: ${Spacings.SPACING_3B};
			width: ${Spacings.SPACING_3B};
		}
		.react-datepicker__month-container {
			width: 100%;
			.react-datepicker__header {
				width: 100%;
				padding: ${Spacings.SPACING_3B} ${Spacings.SPACING_2B} ${Spacings.SPACING_2B};
				background: ${({ theme }) => theme.palette.background.blue};
				.react-datepicker__current-month {
					font-size: ${Text.X_LARGE};
					color: ${({ theme }) => theme.palette.text.white};
					font-weight: ${Text.BOLD_600};
					margin-bottom: ${Spacings.SPACING_2B};
				}
				.react-datepicker__day-name {
					font-size: ${Text.LARGE};
					color: ${({ theme }) => theme.palette.text.white};
					margin: ${Spacings.SPACING_2B};
				}
			}
			.react-datepicker__month {
				
				.react-datepicker__day {
					font-size: ${Text.LARGE};
					margin: ${Spacings.SPACING_2B};
					color: ${({ theme }) => theme.palette.text.darkblack};
				}
				.react-datepicker__day--selected {
					background: ${({ theme }) => theme.palette.background.blue};
					color: ${({ theme }) => theme.palette.text.white};
				}
				.react-datepicker__day--disabled {
					color: ${({ theme }) => theme.palette.text.disabled};
				}
			}
		}
	}

	@media ${Devices.mobile} {
		position: absolute;
		left: initial;
		bottom: initial;
		width: fit-content;
		${getPosition}


		& .react-datepicker {
			.react-datepicker__triangle {
				display: static;
				position: absolute;
				box-shadow: 0 0 ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
				${getTriangleStyles}
				
			}

			.react-datepicker__navigation-icon::before {
				height: ${Spacings.SPACING_2B};
				top: ${Spacings.SPACING_3B};
				width: ${Spacings.SPACING_2B};
			}
			.react-datepicker__month-container {
				border: 1px solid ${({ theme }) => theme.palette.background.blue};
				border-radius:  ${Spacings.SPACING_2B};
				overflow: hidden;
				
				.react-datepicker__header {
					.react-datepicker__current-month {
						font-size: ${Text.LARGE};
					}
					.react-datepicker__day-name {
						font-size: ${Text.MEDIUM};
						margin: ${Spacings.SPACING_1B};
					}
				}
				.react-datepicker__month {
					.react-datepicker__day {
						font-size: ${Text.MEDIUM};
						margin: ${Spacings.SPACING_1B};
					}
				}
			}
		}
	}
`

const IconWrapper = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	pointer-events: none;
	top: 0;
	right: ${Spacings.SPACING_3B};

	& > svg {
		font-size: ${Text.LARGE};
		fill: ${({ selected, theme }) => (selected ? theme.palette.text.blue : theme.palette.text.label)};
		transition: fill 150ms ease-in;
	}
`

function Calendar({
	position = 'bottom',
	label,
	placeholder,
	value,
	minDate,
	maxDate,
	onChange,
	disabled,
	endOfDay = true,
	readOnly,
	showMonthYearPicker = false,
	isSearch = false,
	...rest
}) {
	const [showDatePicker, setShowDatePicker] = useState(false)
	const [selectedDate, setSelectedDate] = useState(convertLongDate(new Date(), endOfDay, isSearch))

	const datePickerRefInput = useRef()
	const datePickerRefWrapper = useRef()

	useEffect(() => {
		if (typeof value === 'number') {
			const val = new Date(value)
			setSelectedDate(val)
		} else if (value instanceof Date) {
			setSelectedDate(value)
		}
	}, [value])

	useEffect(() => {
		const handler = (e) => {
			const target = e.target
			if (datePickerRefInput.current && datePickerRefInput.current.contains(target)) {
				return
			}
			if (datePickerRefWrapper.current && datePickerRefWrapper.current.contains(target)) {
				return
			} else {
				setShowDatePicker(false)
			}
		}

		if (showDatePicker) {
			window.addEventListener('mousedown', handler)
		} else {
			window.removeEventListener('mousedown', handler)
		}

		return () => {
			window.removeEventListener('mousedown', handler)
		}
	}, [showDatePicker])

	const toggleDatePicker = () => {
		setShowDatePicker(!showDatePicker)
	}

	const changeHandler = (date) => {
		setShowDatePicker(false)
		setSelectedDate(date)

		const _d = convertLongDate(date, endOfDay,isSearch)

		if (typeof onChange === 'function') {
			onChange(_d.getTime())
		}
	}
	return (
		<>
			<MobileOverlay show={showDatePicker} />
			<Wrapper>
				<DatePickerInput
					ref={(_ref) => (datePickerRefInput.current = _ref)}
					label={label ?? 'Select date'}
					value={value ? showDateFormat(selectedDate) : '-'}
					type='text'
					onClick={toggleDatePicker}
					placeholder={placeholder ?? 'Select date'}
					focussed={showDatePicker}
					disabled={disabled}
					inputReadOnly
					readOnly={readOnly}
					{...rest}
				/>
				{!readOnly && (
					<IconWrapper selected={showDatePicker}>
						<CalendarToday />
					</IconWrapper>
				)}
				{showDatePicker && (
					<InlineDatePickerWrapper position={position} ref={(_ref) => (datePickerRefWrapper.current = _ref)}>
						<DatePicker
							inline
							disabledKeyboardNavigation
							minDate={minDate}
							maxDate={maxDate}
							selected={selectedDate}
							onChange={changeHandler}
							showMonthYearPicker={showMonthYearPicker}
						/>
					</InlineDatePickerWrapper>
				)}
			</Wrapper>
		</>
	)
}

export default Calendar
