import styled, { css, keyframes } from 'styled-components'

const animation1 = keyframes`
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
`
const animation3 = keyframes`
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
`
const animation2 = keyframes`
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(1.5rem, 0);
    }
`

const Wrapper = styled.div`
	display: inline-block;
	position: relative;
	z-index: 9999;
	
	${({ size }) => {
		return css`
			width: 5rem;
			height: ${size / 4}rem;

			& div {
				position: absolute;
				top: 0;
				width: ${size / 4}rem;
				height: ${size / 4}rem;
				border-radius: 50%;
				background: ${({ theme, color }) => color || theme.palette.background.messageRed};
				animation-timing-function: cubic-bezier(0, 1, 1, 0);
			}

			& div:nth-child(1) {
				left: 0.5rem;
				animation: ${animation1} 0.6s infinite 0.1s;
			}
			& div:nth-child(2) {
				left: 0.5rem;
				animation: ${animation2} 0.6s infinite;
			}
			& div:nth-child(3) {
				left: 2rem;
				animation: ${animation2} 0.6s infinite;
			}
			& div:nth-child(4) {
				left: 3.5rem;
				animation: ${animation3} 0.6s infinite 0.3s;
			}
		`
	}}
`

const Loader = ({ size = 3, color }) => {
	return (
		<Wrapper size={size} color={color}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</Wrapper>
	)
}

export default Loader
