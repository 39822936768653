import styled from 'styled-components'
import { Button, H6, BaseWrapper } from '@base'
import { Spacings, Text } from '@styles'

const Wrapper = styled(BaseWrapper)`
	flex-grow: 1;
	margin-bottom: ${Spacings.SPACING_4B};
`
const EmptyStateWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;

	& > div {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		& > svg {
			font-size: 7rem;
			fill: ${({ theme }) => theme.palette.background.disabled};
		}
	}
`

const EmptyStateHeading = styled(H6)`
	color: ${({ theme }) => theme.palette.text.label};
	font-weight: ${Text.BOLD_600};
	line-height: ${Text.LINE_HEIGHT_MAX};
	margin-bottom: ${Spacings.SPACING_4B};
`

const EmptyState = ({ full, icon, buttonHandler, buttonText, text, noAction }) => {
	const renderContent = () => (
		<EmptyStateWrapper>
			<div>
				{icon}
				<EmptyStateHeading>{text}</EmptyStateHeading>
				{!noAction && (
					<Button small onClick={buttonHandler}>
						{buttonText}
					</Button>
				)}
			</div>
		</EmptyStateWrapper>
	)

	return full ? <Wrapper>{renderContent()}</Wrapper> : renderContent()
}

export default EmptyState
