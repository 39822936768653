import styled from 'styled-components'
import { Spacings, Text } from '@styles'

const Wrapper = styled.div`
	padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_3B};
	background: ${({ theme }) => theme.palette.background.blueLightest};
	border-radius: ${Spacings.SPACING_2B};
	width: 100%;
	height: 100%;
`

const Item = styled.span`
	font-size: ${({ uppercase }) => (uppercase ? Text.SMALL : Text.MEDIUM)};
	display: inline-block;
	color: ${({ theme }) => theme.palette.text.darkblack};
	margin-bottom: ${({ marginBottom }) => (marginBottom ? Spacings.SPACING_1B : 0)};
	text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : '')};
	font-weight: ${({ uppercase }) => (uppercase ? '600' : '500')};
`

const AddressCard = ({ data, showAddress = true }) => {
	return (
		<Wrapper>
			{showAddress && (
				<>
					<Item marginBottom>{data?.complete_address}</Item>
					<br />
				</>
			)}
			<Item uppercase>{data?.city},&#160;</Item>
			<Item uppercase>{data?.state}&#160;-&#160;</Item>
			<Item uppercase>{data?.pincode}</Item>
		</Wrapper>
	)
}

export default AddressCard
