import { Input } from '@base'
import { strings } from '@constants'
import { Spacings, Text } from '@styles'
import { displayAmount } from '@utils'
import { useEffect } from 'react'
import { useCallback, useState } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
	margin: ${({ margin }) => margin};
	background: ${({ theme, viewOnly }) => (viewOnly ? theme.palette.background.white : theme.palette.background.blueLightest)};
	border-radius: ${Spacings.SPACING_2B};
`
const Row = styled.div`
	display: grid;
	grid-template-columns: 34% repeat(3, 21%);
	gap: ${Spacings.SPACING_1B};

	font-size: ${Text.SMALL};
	padding: ${Spacings.SPACING_1B} ${Spacings.SPACING_2B};

	&:last-of-type {
		padding-bottom: ${Spacings.SPACING_2B};
	}
`
const Cell = styled.div`
	display: ${({ noFlex }) => (noFlex ? 'initial' : 'flex')};
	align-items: center;
	justify-content: ${({ alignContent }) => alignContent ?? 'center'};
`

const HeaderRow = styled(Row)`
	font-weight: ${Text.BOLD_600};
	border-bottom: 1px solid ${({ theme }) => theme.palette.background.blueLight};
	margin-bottom: ${Spacings.SPACING_1B};
`
const HeaderCell = styled(Cell)``

const RateCard = ({ data, viewOnly, margin, update, updateKey, isAdmin, isSupplierSide }) => {
	const [hasError, setHasError] = useState({})
	const { items } = data

	const renderHeader = () => {
		return (
			<HeaderRow>
				<HeaderCell alignContent='start'>Product Name</HeaderCell>
				<HeaderCell>Original Quote</HeaderCell>
				<HeaderCell>Counter Quote</HeaderCell>
				<HeaderCell>New Quote</HeaderCell>
			</HeaderRow>
		)
	}

	const onChange = (item) => (value) => {
		update({ productName: item.product_name, updates: { [updateKey]: value } })
	}

	useEffect(() => {
		if (typeof update === 'function') {
			update({ productName: hasError?.productName, updates: { error: hasError?.error } })
		}
	}, [hasError])

	const renderEditableCells = (item) => {
		const editableCell = viewOnly ? (
			<Cell>{displayAmount(item?.[updateKey])}</Cell>
		) : (
			<Cell noFlex>
				<Input
					type='number'
					inline
					small
					value={item.get(updateKey)}
					onChange={onChange(item)}
					max={item.original_price}
					min={isAdmin ? item.user_counter_price ?? 0 : item.mb_counter_price ?? 0}
					disabled={!item.original_price}
					errorMap={{
						rangeOverflow: `Cannot be more than ${displayAmount(item.original_price)}`,
						rangeUnderflow: `Cannot be less than ${displayAmount(isAdmin ? item.user_counter_price : item.mb_counter_price)}`,
					}}
					hasError={(error) => setHasError({ productName: item.product_name, error })}
				/>
			</Cell>
		)

		if (isAdmin) {
			if (isSupplierSide) {
				return (
					<>
						{editableCell}
						<Cell>{displayAmount(item.user_counter_price)}</Cell>
					</>
				)
			} else {
				return (
					<>
						<Cell>{displayAmount(item.user_counter_price)}</Cell>
						{editableCell}
					</>
				)
			}
		} else {
			if (isSupplierSide) {
				return (
					<>
						<Cell>{displayAmount(item.mb_counter_price)}</Cell>
						{editableCell}
					</>
				)
			} else {
				return (
					<>
						{editableCell}
						<Cell>{displayAmount(item.mb_counter_price)}</Cell>
					</>
				)
			}
		}
	}

	const renderQuotes = () => {
		return items.map((_i) => {
			return (
				<Row key={_i.product_name}>
					<Cell alignContent='start'>{_i.product_name}</Cell>
					<Cell>{displayAmount(_i.original_price) ?? '-'}</Cell>
					{renderEditableCells(_i)}
				</Row>
			)
		})
	}

	return (
		<Wrapper margin={margin} viewOnly={viewOnly}>
			{renderHeader()}
			{renderQuotes()}
		</Wrapper>
	)
}

export default RateCard
