import React from 'react'
import styled from 'styled-components'
import { Devices, Spacings, Text } from '@styles'
import { Button } from '@base'
import { H4 } from '../../basic/Headings'
import { strings } from '@constants'

const CardWrapper = styled.div`
	width: 100%;
	padding: ${Spacings.SPACING_4B};
`

const TextWrapper = styled(H4)`
	color: ${({ theme }) => theme.palette.text.darkblack};
`

const CardFooterWrapper = styled.div`
	display: flex;
	flex-direction: row;

	margin-top: ${Spacings.SPACING_6B};

	& > button {
		width: 50%;
		min-height: 3rem;
		font-size: ${Text.LARGE};
	}

	@media ${Devices.tablet} {
		justify-content: flex-end;

		& > button {
			width: initial;
			font-size: ${Text.MEDIUM};
			min-height: 2.25rem;
		}
	}
`

const ConfirmBox = (props) => {
	const { title, confirmAction } = props

	const handlerClose = () => {
		confirmAction(false)
	}

	const handlerConfirm = () => {
		confirmAction(true)
	}

	return (
		<CardWrapper>
			<TextWrapper>{title}</TextWrapper>
			<CardFooterWrapper>
				<Button xs type='secondary' variant='destructive' onClick={handlerClose}>
					{props.cancelText || strings('cancel')}
				</Button>
				<Button xs onClick={handlerConfirm} margin={`0 0 0 ${Spacings.SPACING_3B}`}>
					{props.confirmText || strings('confirm')}
				</Button>
			</CardFooterWrapper>
		</CardWrapper>
	)
}

export default ConfirmBox
