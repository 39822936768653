import styled, { css } from 'styled-components'
import { strings } from '@constants'
import { Spacings, Text } from '@styles'
import { H6 } from './Headings'

const getPillStyle = ({ theme, status = '', color, background }) => {
	switch (status.toLowerCase()) {
		case 'quote_per_kg':
			return css`
				background: ${({ theme }) => theme.palette.background.greenLightest};
				border: 1px solid ${({ theme }) => theme.palette.background.greenLight};
			`
		case 'active':
			return css`
				background: ${theme.palette.background.blue};
				color: ${theme.palette.text.white};
			`
		case 'custom':
			return css`
				background: ${background};
				color: ${color};
			`
		default:
			return css`
				background: ${({ theme }) => theme.palette.background.redLightest};
				border: 1px solid ${({ theme }) => theme.palette.background.redLight};
			`
	}
}

const InfoPill = styled.span`
	${getPillStyle}
	font-size: ${Text.SMALL};
	padding: 0 ${Spacings.SPACING_3B};
	border-radius: ${Spacings.SPACING_4B};
	display: inline-flex;
	align-items: center;
	width: fit-content;
	margin: ${({ margin }) => margin};
	max-height: 2.25rem;
	min-height: 2rem;
	line-height: ${Text.LINE_HEIGHT_MIN};
`
const Label = styled.span`
	color: ${({ theme }) => theme.palette.text.black};
	margin-right: ${Spacings.SPACING_1B};
`
const Info = styled.span`
	font-weight: ${Text.BOLD_600};
	font-size: ${Text.SMALL};
	color: ${({ theme }) => theme.palette.text.darkblack};
	display: flex;
`

const CardSpan = styled(H6)`
	line-height: 50%;
	margin: ${Spacings.SPACING_1B} 0px 0px 0.1rem;
	font-size: ${Text.EXTRA_SMALL};
`
const Pill = ({ label, value, margin, message = null, ...props }) => {
	return (
		<InfoPill margin={margin} {...props}>
			<Label>{label}</Label>
			<Info>
				{value} {message && <CardSpan>{message}</CardSpan>}
			</Info>
		</InfoPill>
	)
}

export default Pill
