import { useState } from 'react'
import { useRef } from 'react'
import styled, { css, withTheme } from 'styled-components'
import { Close, Error, Maximize, Message, Minimize, Send } from '@material-ui/icons'
import { BasicButton, Button, EmptyState, H5, H6, Input, Loader } from '@base'
import { Spacings, Text } from '@styles'
import RateCard from './RateCard'
import moment from 'moment/moment'
import { useEffect } from 'react'
import { useEnter } from '@hooks'
import { CHAT_STATUS } from '@constants'

const Wrapper = styled.section`
	width: 100%;
	height: 100%;
	background: ${({ theme }) => theme.palette.background.white};
	display: flex;
	flex-direction: column;
	position: relative;
`
const Header = styled.div`
	display: flex;
	align-items: center;
	padding: ${Spacings.SPACING_3B} ${Spacings.SPACING_4B};
	background: ${({ theme, isSupplierSide }) => (isSupplierSide ? theme.palette.background.redLight : theme.palette.background.blueLight)};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
`
const Body = styled.div`
	flex-grow: 1;
	padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_4B} 0;
	display: flex;
	flex-direction: column;
	overflow: scroll;

	${({ withLoader }) => {
		if (withLoader) {
			return css`
				align-items: center;
				justify-content: center;
			`
		}
	}}

	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}
	/* Hide scrollbar for IE, Edge and Firefox */
	& {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
`
const Footer = styled.div`
	padding: ${Spacings.SPACING_3B} ${Spacings.SPACING_4B};
	display: flex;
	align-items: center;
	justify-content: ${({ justifyContent }) => justifyContent};
	background: ${({ theme }) => theme.palette.background.blueLightest};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
`

const IconWrapper = styled.div`
	margin: ${({ margin }) => margin};
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	& > svg {
		font-size: ${Text.X_LARGE};
		color: ${({ color }) => color};
	}
`

const InputWrapper = styled.div`
	flex-grow: 1;
	margin-right: ${Spacings.SPACING_1B};
`

const SendButton = styled(BasicButton)`
	justify-content: center;
	border-radius: 50%;
	height: 2.35rem;
	width: 2.35rem;
	margin-left: ${Spacings.SPACING_2B};

	& > svg {
		fill: ${({ theme }) => theme.palette.text.white};
		font-size: ${Text.X_LARGE};
	}
`

const CreateOfferWrapper = styled.div`
	display: flex;
	flex-direction: column;

	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);

	position: absolute;
	width: 100%;
	height: ${({ show }) => (show ? 'calc(100% - 6.7rem)' : '0')};
	top: 2.75rem;
	background: ${({ theme }) => theme.palette.background.blackGrey + '5e'};

	visibility: ${({ show }) => (show ? 'visible' : 'hidden')};

	overflow: scroll;
	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}
	/* Hide scrollbar for IE, Edge and Firefox */
	& {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
`
const ButtonWrapper = styled.div`
	display: flex;
	& > button {
		height: 2.35rem;
	}
`

const SubArrayMessageWrapper = styled.div`
	display: flex;
	flex-direction: column;
`
const BasicMessageWrapper = styled.div`
	margin: ${Spacings.SPACING_1B} 0 ${Spacings.SPACING_1B};
	align-self: ${({ isOwn }) => (isOwn ? 'flex-end' : 'flex-start')};
`

const TextMessageWrapper = styled(BasicMessageWrapper)`
	max-width: 60%;
`

const Avatar = styled.div`
	width: ${({ fitContent }) => (fitContent ? 'fit-content' : '1.75rem')};
	height: 1.75rem;
	padding: ${({ fitContent }) => (fitContent ? `0 ${Spacings.SPACING_2B}` : 0)};
	border-radius: ${Spacings.SPACING_6B};
	background: ${({ theme, isOwn }) => (isOwn ? theme.palette.background.yellow : theme.palette.background.blue)};
	color: ${({ theme }) => theme.palette.text.white};
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: ${Spacings.SPACING_2};
	margin-top: ${Spacings.SPACING_1B};
	font-size: ${Text.EXTRA_SMALL};
	${({ isOwn }) => {
		if (isOwn) {
			return css`
				margin-left: auto;
				margin-right: -${Spacings.SPACING_2B};
				border-bottom-left-radius: ${Spacings.SPACING_2B};
			`
		}
		return css`
			margin-left: -${Spacings.SPACING_2B};
			border-bottom-right-radius: ${Spacings.SPACING_2B};
		`
	}}
`

const OfferMessageWrapper = styled(BasicMessageWrapper)`
	position: sticky;
	top: -${Spacings.SPACING_2B};
	background: ${({ theme }) => theme.palette.text.white};
	margin: 0 -${Spacings.SPACING_4B};
	padding: 0 ${Spacings.SPACING_4B};
	width: calc(100% + ${Spacings.SPACING_8B});
`

const RateCardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: ${Spacings.SPACING_2B};
	box-sizing: border-box;
	padding: 0 ${Spacings.SPACING_2B};
	background: ${({ theme, isOwn }) => (isOwn ? theme.palette.background.chatMessageYellow : theme.palette.background.chatMessageBlue)};
`

const MessageText = styled.div`
	padding: ${Spacings.SPACING_2B};
	font-size: ${Text.SMALL};
	background: ${({ theme, isOwn }) => (isOwn ? theme.palette.background.chatMessageYellow : theme.palette.background.chatMessageBlue)};
	border-radius: ${Spacings.SPACING_2B};
`
const MessageMeta = styled.div`
	margin-top: ${Spacings.SPACING_1B};
	font-size: ${Text.EXTRA_SMALL};
	color: ${({ theme }) => theme.palette.text.label};
	display: flex;
	justify-content: ${({ isOwn }) => (isOwn ? 'flex-end' : 'flex-start')};
`

const LastOfferHeading = styled(H6)`
	margin-top: ${Spacings.SPACING_2B};
	color: ${({ theme, isOwn }) => (isOwn ? theme.palette.text.red : theme.palette.text.blue)};
`

const OfferStatus = styled.div`
	padding: ${Spacings.SPACING_3B};
	background: ${({ theme, isSuccess }) => (isSuccess ? theme.palette.background.green : theme.palette.background.red)};
	border-radius: ${Spacings.SPACING_2B};
	margin-bottom: ${Spacings.SPACING_2B};
	color: ${({ theme, isSuccess }) => (isSuccess ? theme.palette.text.black : theme.palette.text.white)};
	font-weight: ${Text.BOLD_600};
	font-size: ${Text.SMALL};
	display: flex;
	justify-content: center;
`

const LiveNegotiation = ({
	theme,
	id,
	onClose,
	data,
	onSubmit,
	onMinimise,
	onMaximise,
	updateKey = 'user_counter_price',
	onCreateOfferClick,
	createOfferButtonText = '',
	isAdmin,
	isSupplierSide,
	chatStatus,
	viewOnly,
	closeNegotiation,
	updateOfferHandler,
	userId,
	closed,
}) => {
	viewOnly = viewOnly || closed

	const bodyRef = useRef()
	const [minimised, setMinimised] = useState()
	const [newOffer, setNewOffer] = useState()
	const [message, setMessage] = useState()

	const offerStatus = data?.offerStatus

	const disableSendButton = newOffer ? !newOffer.isValid(updateKey) : !message

	useEffect(() => {
		setTimeout(() => {
			if (bodyRef.current) {
				bodyRef.current.scrollTop = bodyRef.current?.scrollHeight
			}
		})
	}, [data, minimised])

	useEffect(() => {
		if (userId) {
			setMessage('')
			discardCounterOffer()
		}
	}, [userId])

	const enterHandler = () => {
		if (disableSendButton || viewOnly) return

		newOffer ? sendOffer() : sendMessage()
	}

	useEnter(enterHandler)

	const miniMaxHandler = () => {
		setMinimised((_m) => {
			_m ? onMaximise() : onMinimise()
			return !_m
		})
	}

	const update = ({ productName, updates }) => {
		setNewOffer((_offer) => _offer.updateItem(productName, updates))
	}

	const createOfferHandler = () => {
		onCreateOfferClick((a) => setNewOffer(a))
		setMessage('')
	}
	const discardCounterOffer = () => {
		setNewOffer(null)
	}

	const sendOffer = () => {
		if (!newOffer) return

		onSubmit('offer', newOffer).then(() => {
			setNewOffer(null)
		})
	}

	const sendMessage = () => {
		if (!message) return

		onSubmit('text', message).then(() => {
			setMessage('')
		})
	}

	const onMessageChange = (value) => {
		setMessage(value)
	}

	const renderMessage = (_m, _si, _i, _arr) => {
		const key = _m.created_at + `_message_${_si}_${_i}`
		const isOwn = _m.isOwnMessage()
		const isLastMessageBlock = _si === _arr.length - 1
		const messageMeta = _m.created_at ? <MessageMeta isOwn={isOwn}>{moment(_m.created_at).format('D/MM/YYYY, h:mm a')}</MessageMeta> : null
		const avatar = (
			<Avatar isOwn={isOwn} fitContent={isAdmin && !isOwn}>
				{isOwn ? 'You' : isAdmin ? (isSupplierSide ? 'Supplier' : 'Buyer') : 'MB'}
			</Avatar>
		)

		if (_m.type === 'offer') {
			return (
				<OfferMessageWrapper isOwn={isOwn} key={key}>
					{avatar}
					<RateCardWrapper isOwn={isOwn}>
						{isLastMessageBlock && (
							<LastOfferHeading isOwn={isOwn}>{isOwn ? 'Latest offer sent' : 'Latest offer received'}</LastOfferHeading>
						)}
						<RateCard
							margin={`${Spacings.SPACING_2B} 0`}
							viewOnly
							data={_m.offer_details}
							isAdmin={isAdmin}
							isSupplierSide={isSupplierSide}
							updateKey={updateKey}
						/>
						{!_m.status && isLastMessageBlock && !viewOnly && !isOwn && (
							<ButtonWrapper>
								<Button
									xs
									margin={`0 ${Spacings.SPACING_3B} ${Spacings.SPACING_2B} auto`}
									variant='destructive'
									onClick={() => updateOfferHandler(false)}
								>
									Reject Offer
								</Button>
								<Button xs margin={`0 0 ${Spacings.SPACING_2B} 0`} onClick={() => updateOfferHandler(true)}>
									Accept Offer
								</Button>
							</ButtonWrapper>
						)}
						{_m.status && (
							<OfferStatus isSuccess={_m.status.toLowerCase() === 'accepted'}>This offer was {_m.status.toLowerCase()}</OfferStatus>
						)}
					</RateCardWrapper>
					{messageMeta}
				</OfferMessageWrapper>
			)
		}
		return (
			<TextMessageWrapper isOwn={isOwn} key={key}>
				{avatar}
				<MessageText isOwn={isOwn}>{_m.summary}</MessageText>
				{messageMeta}
			</TextMessageWrapper>
		)
	}

	const renderBody = () => {
		if (chatStatus === CHAT_STATUS.LOADING) {
			return (
				<Body withLoader>
					<Loader size={2} />
				</Body>
			)
		}

		if (chatStatus === CHAT_STATUS.ERROR) {
			return (
				<Body withLoader>
					<EmptyState icon={<Error />} noAction text='Failed to load the conversation.' />
				</Body>
			)
		}

		if (chatStatus === CHAT_STATUS.NO_MESSAGE) {
			return (
				<Body withLoader>
					<EmptyState icon={<Message />} noAction text='Start a conversation now!' />
				</Body>
			)
		}

		const messages = data?.messages ?? []
		return (
			<Body ref={(_ref) => (bodyRef.current = _ref)}>
				{messages.map((_subArray, _si, _arr) => {
					return <SubArrayMessageWrapper>{_subArray.map((_m, _i) => renderMessage(_m, _si, _i, _arr))}</SubArrayMessageWrapper>
				})}
			</Body>
		)
	}
	const renderFooterActions = () => {
		if (newOffer) {
			return (
				<ButtonWrapper>
					<Button xs margin={`0 0 0 ${Spacings.SPACING_2B}`} onClick={sendOffer} disabled={disableSendButton}>
						Send Offer
					</Button>
					<Button variant='destructive' type='secondary' xs margin={`0 0 0 ${Spacings.SPACING_3B}`} onClick={discardCounterOffer}>
						Discard Offer
					</Button>
				</ButtonWrapper>
			)
		} else {
			return (
				<ButtonWrapper>
					<SendButton small onClick={sendMessage} disabled={disableSendButton}>
						<Send />
					</SendButton>
					{offerStatus?.toLowerCase() !== 'accepted' && (
						<Button xs margin={`0 0 0 ${Spacings.SPACING_3B}`} onClick={createOfferHandler}>
							{createOfferButtonText ?? 'Create Counter Offer'}
						</Button>
					)}
				</ButtonWrapper>
			)
		}
	}

	const showMinimizeButton = onMinimise && onMaximise

	return (
		<Wrapper>
			<Header isSupplierSide={isSupplierSide}>
				<H5 color={theme.palette.text.white}>Live Negotiation ({id})</H5>
				{!viewOnly && (
					<Button xs margin={`0 0 0 auto`} variant='primary' type='secondary' onClick={closeNegotiation}>
						End Negotiation
					</Button>
				)}
				{showMinimizeButton && (
					<IconWrapper
						onClick={miniMaxHandler}
						color={theme.palette.text.white}
						margin={`0 0 0 ${viewOnly ? 'auto' : Spacings.SPACING_3B}`}
					>
						{minimised ? <Maximize /> : <Minimize />}
					</IconWrapper>
				)}
				<IconWrapper
					onClick={onClose}
					color={theme.palette.text.white}
					margin={`0 0 0 ${showMinimizeButton || !viewOnly ? Spacings.SPACING_3B : 'auto'}`}
				>
					<Close />
				</IconWrapper>
			</Header>
			{!minimised && (
				<>
					{renderBody()}
					<CreateOfferWrapper show={!!newOffer}>
						{newOffer && (
							<RateCard
								margin={Spacings.SPACING_2B}
								data={newOffer}
								update={update}
								updateKey={updateKey}
								isAdmin={isAdmin}
								isSupplierSide={isSupplierSide}
							/>
						)}
					</CreateOfferWrapper>
					<Footer justifyContent={viewOnly ? 'center' : 'initial'}>
						{closed ? (
							<H6>This conversation ended. You can only view it.</H6>
						) : viewOnly ? (
							<H6>You can only view the past conversation.</H6>
						) : (
							<>
								<InputWrapper>
									{!newOffer && (
										<Input inline type='text' placeholder='Enter your message here' value={message} onChange={onMessageChange} />
									)}
								</InputWrapper>
								{renderFooterActions()}
							</>
						)}
					</Footer>
				</>
			)}
		</Wrapper>
	)
}

export default withTheme(LiveNegotiation)
