import styled, { css } from 'styled-components'
import { Info } from '@material-ui/icons'
import { Devices, Spacings, Text } from '@styles'

const defaultStyles = css`
	border-radius: ${Spacings.SPACING_2B};
	border: 1px solid
		${({ theme, warning, info }) =>
			info ? theme.palette.background.blue : warning ? theme.palette.background.red : theme.palette.background.yellow};
`

const BannerWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: ${Spacings.SPACING_2B};
	background: ${({ theme, warning, info }) =>
		info ? theme.palette.background.white : warning ? theme.palette.background.redLightest : theme.palette.background.yellowLightest};
	margin: ${({ margin }) => margin ?? 0};
	flex-grow: 1;

	${defaultStyles}

	${({ extendInMobile }) => {
		if (extendInMobile) {
			return css`
				border-radius: 0;
				border-left: none;
				border-right: none;
			`
		}
	}}

	@media ${Devices.tablet} {
		${defaultStyles}
	}
`

const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;
	margin-right: ${Spacings.SPACING_2B};
	& > svg {
		fill: ${({ theme, warning, info }) =>
			info ? theme.palette.background.blue : warning ? theme.palette.background.red : theme.palette.background.yellow};
	}
`

const Content = styled.div`
	font-size: ${Text.SMALL};
	line-height: ${Text.LINE_HEIGHT_MEDIUM};
	flex-grow: 1;
`

const Banner = ({ text, margin, extendInMobile, warning, info }) => {
	return (
		<BannerWrapper margin={margin} extendInMobile={extendInMobile} warning={warning} info={info}>
			<IconWrapper warning={warning} info={info}>
				<Info fontSize='small' />
			</IconWrapper>
			<Content>{text}</Content>
		</BannerWrapper>
	)
}

export default Banner
