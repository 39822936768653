import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CheckCircle, Close, Error, Info } from '@material-ui/icons'
import styled from 'styled-components'
import { showToast } from '@data/state/action'
import { Devices, Spacings, Text } from '@styles'
import { H5, H6 } from '@base'

export const TOAST_TYPE = {
	SUCCESS: 'SUCCESS',
	ERROR: 'ERROR',
	INFO: 'INFO',
	NOTIFICATION: 'NOTIFICATION',
}

const getBackground = ({ type, theme }) => {
	if (type === TOAST_TYPE.SUCCESS) return theme.palette.background.greenDark
	if (type === TOAST_TYPE.ERROR) return theme.palette.background.red
	if (type === TOAST_TYPE.INFO) return theme.palette.background.blue
	return theme.palette.background.blackGrey
}

const getIcon = (type) => {
	if (type === TOAST_TYPE.SUCCESS) return <CheckCircle />
	if (type === TOAST_TYPE.ERROR) return <Error />
	if (type === TOAST_TYPE.INFO) return <Info />
	return null
}

const Snackbar = styled.div`
	padding: ${Spacings.SPACING_4B};
	border-radius: ${Spacings.SPACING_2B};
	background: ${getBackground};
	color: ${({ theme }) => theme.palette.text.white};
	box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);

	display: flex;
	align-items: center;
	z-index: 1001;

	position: fixed;
	margin: ${Spacings.SPACING_4B};
	width: -webkit-fill-available;
	transition: bottom 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

	left: 0;
	bottom: ${({ open }) => (open ? 0 : '-100%')};
	cursor: ${({ cursor }) => cursor};

	@media ${Devices.tablet} {
		width: 22rem;
		max-width: 22rem;
	}
`

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
`
const CloseIcon = styled.div`
	position: absolute;
	top: ${Spacings.SPACING_1B};
	right: ${Spacings.SPACING_1B};
	& > svg {
		font-size: ${Text.MEDIUM};
	}
`

const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-right: ${Spacings.SPACING_3B};
	& > svg {
		font-size: ${Text.X_LARGE};
	}
`

const Message = styled(H5)``
const Body = styled(H6)`
	margin-top: ${Spacings.SPACING_2B};
`

const Toast = () => {
	const dispatch = useDispatch()
	const toast = useSelector((state) => state.getIn(['root', 'toast']))

	const timeoutId = useRef()

	useEffect(() => {
		if (toast.open) {
			if (toast.options?.autoHide) {
				clearTimeout(timeoutId.current)
				timeoutId.current = setTimeout(() => {
					dispatch(showToast(false))
					if (toast.options?.callActionAfterHide && toast.options?.actions) {
						toast.options.actions()
					}
				}, toast.options.autoHideTime)

				// When we want to perform action, but dont want to wait for toast to disappear
				if (!toast.options?.callActionAfterHide && toast.options?.actions) {
					toast.options.actions()
				}
			} else {
				// dispatch(showToast(false))
				if (!toast.options?.callActionAfterHide && toast.options?.actions) {
					toast.options.actions()
				}
			}
		}
		return () => {
			clearTimeout(timeoutId.current)
		}
	}, [toast.open, toast.options?.resetTimer])

	const { type, autoHide, closable, onClick } = toast?.options

	const close = (e) => {
		if (e) {
			e.stopPropagation()
		}
		dispatch(showToast(false))
	}

	const toastClick = () => {
		if (typeof onClick === 'function') {
			close()
			onClick()
		}
	}

	const isNotification = type === TOAST_TYPE.NOTIFICATION

	return (
		<Snackbar open={toast?.open} type={type} onClick={toastClick} cursor={typeof onClick === 'function' ? 'pointer' : 'initial'}>
			{type ? <IconWrapper>{getIcon(type)}</IconWrapper> : null}
			{!autoHide && closable && (
				<CloseIcon onClick={close}>
					<Close />
				</CloseIcon>
			)}
			<ContentWrapper>
				<Message>{toast?.title}</Message>
				{isNotification && toast.options?.body && <Body>{toast.options?.body}</Body>}
			</ContentWrapper>
		</Snackbar>
	)
}

export default Toast
