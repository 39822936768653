import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Area, AreaChart } from 'recharts'
import { strings } from '@constants'
import { CustomizedYAxisTick, CustomizedXAxisTick, CustomTooltip, CHART_COLORS, FILL_COLORS } from './CustomElements'

const AreaChartComponent = ({ data }) => {
	return (
		<ResponsiveContainer width='100%' height='100%' minHeight='240px' minWidth='240px'>
			<AreaChart
				width={500}
				height={300}
				data={data?.data}
				margin={{
					top: 5,
					right: 15,
					left: -10,
					bottom: -10,
				}}
			>
				<CartesianGrid strokeDasharray='3 3' />
				<XAxis dataKey={data?.xKey} interval={0} tickSize={4} tick={<CustomizedXAxisTick />} />
				<YAxis
					tickSize={4}
					tick={<CustomizedYAxisTick />}
					label={{ value: data?.yLabel, angle: -90, fontSize: 10, dx: -10, fill: '#595A5B' }}
				/>
				<Tooltip content={<CustomTooltip />} />
				<Legend verticalAlign='top' align='center' height={36} iconSize={8} />
				{Array.from({ length: data?.numberOfItems ?? 1 }).map((_, index) => (
					<Area
						type='monotone'
						key={`chart_bar_${data.header}${index}`}
						dataKey={data?.[`yKey${index}`]}
						name={strings(data?.[`yKey${index}`] ?? '')}
						stroke={CHART_COLORS[index]}
						fill={FILL_COLORS[index]}
						unit={data?.unit}
						activeDot={{ r: 8 }}
						formatter={data?.formatter}
					/>
				))}
			</AreaChart>
		</ResponsiveContainer>
	)
}

export default AreaChartComponent
