import styled, { css } from 'styled-components'
import { strings } from '@constants'
import logoIconNew from '@assets/login/_logo.png'
import logoIcon from '@assets/login/logo.png'
import { Devices, Spacings, Text } from '@styles'

const Wrapper = styled.div`
	display: flex;
	flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
	align-items: center;
	user-select: none;
	cursor: ${({ clickable }) => (clickable ? 'pointer' : 'initial')};
`
const Img = styled.img`
	width: ${({ size }) => size ?? '3.75rem'};
`
const LogoText = styled.span`
	font-size: ${({ isLogin }) => (isLogin ? Text.LARGE : Text.SMALL)};
	font-weight: ${Text.BOLD_600};
	color: ${({ theme, isLogin }) => (isLogin ? theme.palette.text.white : theme.palette.text.messageRed)};
	${({ horizontal }) => {
		if (horizontal) {
			return css`
				margin-left: ${Spacings.SPACING_3B};
				font-size: ${Text.LARGE};
			`
		}
		return css`
			margin-top: ${Spacings.SPACING_2B};
		`
	}}
	& > span {
		font-weight: normal;
	}
`
const PrivacyPolicyWrapper = styled.div`
	font-size: ${Text.SMALL};
	${({ IsExtraSmall }) => {
		if (IsExtraSmall) {
			return css`
				font-size: ${Text.EXTRA_SMALL};
			`
		}
	}}
	text-align: center;
	color: ${({ theme }) => theme.palette.text.darkblack};
	margin-top: ${Spacings.SPACING_4B};
	display: none;
	@media ${Devices.mobile} {
		display: block;
	}
`

const Logo = ({ size, horizontal = false, clickHandler, isLogin = false }) => {
	if (isLogin) {
			return (
				<Wrapper horizontal={horizontal} clickable={!!clickHandler} onClick={() => clickHandler && clickHandler()}>
					<Img src={logoIcon} size={size} alt={strings('metalbook')} />
					<PrivacyPolicyWrapper>{strings('new_buy_sell_steel')}</PrivacyPolicyWrapper>
				</Wrapper>
			)
		} else {
			return (
				<Wrapper horizontal={horizontal} clickable={!!clickHandler} onClick={() => clickHandler && clickHandler()}>
					<Img src={logoIconNew} size={size} alt={strings('metalbook')} />
					<LogoText horizontal={horizontal} isLogin={isLogin}>
						METAL
						<span>BOOK</span>
					</LogoText>
				</Wrapper>
			)
		}
	
}

export default Logo
