import styled from 'styled-components'

const CheckboxWrapper = styled.span`
	height: ${({ size }) => (size ? size + 'rem' : '1.5rem')};
	width: ${({ size }) => (size ? size + 'rem' : '1.5rem')};
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
	border-radius: 4px;
	background: ${({ theme }) => theme.palette.background.white};
	box-shadow: inset 0 0 0px 3px ${({ theme, disabled }) => (disabled ? theme.palette.text.greydark : theme.palette.text.blue)};

	& > input {
		top: 0;
		left: 0;
		width: 100%;
		cursor: inherit;
		height: 100%;
		margin: 0;
		opacity: 0;
		padding: 0;
		z-index: 1;
		position: absolute;
	}

	& > svg {
		height: ${({ size }) => (size ? size + 'rem' : '1.5rem')};
		width: ${({ size }) => (size ? size + 'rem' : '1.5rem')};
		font-size: 1.5rem;
		transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		flex-shrink: 0;
		user-select: none;
		fill: ${({ checked, theme, disabled }) => (checked ? (disabled ? theme.palette.text.greydark : theme.palette.text.blue) : 'transparent')};
	}
`

const Checkbox = ({ id, size, value, checked, onChange, disabled, title = '', onClick = () => {} }) => {
	return (
		<CheckboxWrapper size={size} checked={checked} disabled={disabled}>
			<input type='checkbox' id={id} value={value} checked={checked} disabled={disabled} onChange={onChange} onClick={onClick} title={title} />
			<svg focusable='false' viewBox='0 0 24 24'>
				<path d='M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z'></path>
			</svg>
		</CheckboxWrapper>
	)
}

export default Checkbox
