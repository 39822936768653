import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { reducer, actions, initialState as _initialState } from './_tableReducer'

const useTableReducer = (applicableFilters = {}, initialState = _initialState) => {
	const [searchParams, setSearchParams] = useSearchParams()
	const [state, dispatch] = useReducer(reducer, initialState)

	const previousSearchParams = useRef()

	const hasChanged = (previous, current) => {
		if ((!previous && current) || [...previous.keys()].length !== [...current.keys()].length) {
			return true
		}

		return [...previous.entries()].reduce((acc, [key, val]) => {
			if (acc || !current.has(key) || (current.has(key) && current.get(key) !== val)) return true
			return false
		}, false)
	}

	const applicableSearchParams = useMemo(() => {
		const _applicableFilters = {
			...applicableFilters,
			page: 'page',
		}
		const _current = Object.entries(_applicableFilters).reduce((acc, [key, _]) => {
			if (key === 'page') {
				const _page = searchParams.get(key)
				acc[key] = isNaN(+_page) ? 0 : +_page
			} else if (searchParams.has(key)) {
				acc[key] = searchParams.get(key)
			}
			return acc
		}, {})
		return _current
	}, [applicableFilters, searchParams])

	const updateQueryParams = useCallback(
		(page) => {
			const existingsParams = [...searchParams.entries()].reduce(
				(acc, [key, val]) => ({
					...acc,
					[key]: val,
				}),
				{}
			)
			setSearchParams({ ...existingsParams, page })
		},
		[searchParams, setSearchParams]
	)

	const updateActions = useMemo(() => {
		return {
			...actions,
			setPage: (page) => {
				updateQueryParams(page)
				return {}
			},
			changePage: (next) => {
				updateQueryParams(next ? applicableSearchParams.page + 1 : applicableSearchParams.page - 1)
				return {}
			},
		}
	}, [applicableSearchParams, updateQueryParams])

	useEffect(() => {
		const _applicableSearchParamsObject = new URLSearchParams(applicableSearchParams)

		if (hasChanged(previousSearchParams.current, _applicableSearchParamsObject)) {
			const currentQuery = {
				page: state.query.page,
				...state.query.filters,
			}
			if (currentQuery && !_applicableSearchParamsObject.toString()) {
				dispatch(
					actions.setQuery({
						page: 0,
						filters: null,
					})
				)
				previousSearchParams.current = null
				return
			}

			if (_applicableSearchParamsObject && _applicableSearchParamsObject.toString()) {
				const _applicableFilters = {
					...applicableFilters,
					page: 'page',
				}
				const updateQuery = Object.entries(_applicableFilters).reduce((acc, [key, val]) => {
					if (_applicableSearchParamsObject.has(key)) {
						if (key === 'page') {
							return {
								...acc,
								page: +_applicableSearchParamsObject.get(key),
							}
						} else {
							return {
								...acc,
								filters: {
									...(acc?.filters ?? {}),
									[val]: _applicableSearchParamsObject.get(key),
								},
							}
						}
					}
					return acc
				}, {})
				dispatch(actions.setQuery(updateQuery))
			}
			previousSearchParams.current = _applicableSearchParamsObject
		}
	}, [applicableSearchParams])

	return [state, dispatch, updateActions]
}

export default useTableReducer
