import styled from 'styled-components'
import { Check } from '@material-ui/icons'
import { Devices, Spacings, Text } from '@styles'

const StepperWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	user-select: none;
`
const StepWrapper = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	max-width: 15rem;
`

const Label = styled.span`
	margin-left: ${Spacings.SPACING_2B};
	width: fit-content;
	color: rgba(0, 0, 0, 0.87);
	color: ${({ alive, theme }) => (alive ? theme.palette.text.black : '#00000099')};
	font-weight: ${({ alive, theme }) => (alive ? Text.BOLD_600 : Text.BOLD_500)};
	font-size: ${Text.EXTRA_SMALL};
	line-height: 1.43;
	letter-spacing: 0.01071em;
	display: block;
	transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

	@media ${Devices.tablet} {
		font-size: ${Text.SMALL};
	}
`
const StepDot = styled.span`
	flex-shrink: 0;
	display: flex;
	min-height: 1.125rem;
	min-width: 1.125rem;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background: ${({ alive, theme }) => (alive ? theme.palette.background.blue : '#00000099')};
	color: ${({ theme }) => theme.palette.text.white};
	font-size: ${Text.EXTRA_SMALL};
	& > svg {
		font-size: ${Text.EXTRA_SMALL};
	}

	@media ${Devices.tablet} {
		min-height: 1.25rem;
		min-width: 1.25rem;
		font-size: ${Text.SMALL};
		& > svg {
			font-size: ${Text.SMALL};
		}
	}
`
const StepConnector = styled.div`
	flex: 1 1 auto;

	& > span {
		display: block;
		border-color: ${({ theme }) => theme.palette.background.border};
		border-top-style: solid;
		border-top-width: 1px;
	}
`
const StepLabel = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	padding: 0 ${Spacings.SPACING_2B};
`

const Step = ({ data, isFirst, isLast, active, done }) => (
	<StepWrapper>
		{!isFirst && (
			<StepConnector>
				<span></span>
			</StepConnector>
		)}
		<StepLabel>
			<StepDot alive={active || done}>{done ? <Check /> : data.id}</StepDot>
			<Label alive={active || done}>{data.label}</Label>
		</StepLabel>
		{!isLast && (
			<StepConnector>
				<span></span>
			</StepConnector>
		)}
	</StepWrapper>
)

const Stepper = ({ steps, selectedStep }) => {
	return (
		<StepperWrapper>
			{steps.map((step, index) => (
				<Step
					key={`step-${step.id}`}
					data={step}
					active={step.id === selectedStep}
					done={step.id < selectedStep}
					isFirst={index === 0}
					isLast={index === steps.length - 1}
				/>
			))}
		</StepperWrapper>
	)
}

export default Stepper
