const actionTypes = {
	TABLE_DATA_PROGRESS: 'TABLE_DATA_PROGRESS',
	TABLE_DATA_SUCCESS: 'TABLE_DATA_SUCCESS',
	TABLE_DATA_FAILURE: 'TABLE_DATA_FAILURE',
	SET_QUERY: 'SET_QUERY',
}

export const actions = {
	fetchTableDataProgress: () => ({
		type: actionTypes.TABLE_DATA_PROGRESS,
	}),
	fetchTableDataSuccess: (data, count) => ({
		type: actionTypes.TABLE_DATA_SUCCESS,
		data,
		count,
	}),
	fetchTableDataFailure: (error) => ({
		type: actionTypes.TABLE_DATA_FAILURE,
		error,
	}),
	setQuery: (query) => ({
		type: actionTypes.SET_QUERY,
		query,
	}),
}

export const initialState = {
	query: {
		page: 0,
		filters: null,
	},
	loading: true,
	error: false,
	data: [],
	total_count: 0,
}

export const reducer = (state, action) => {
	switch (action.type) {
		case actionTypes.TABLE_DATA_PROGRESS: {
			return {
				...state,
				loading: true,
			}
		}
		case actionTypes.TABLE_DATA_SUCCESS: {
			return {
				...state,
				loading: false,
				data: action.data,
				total_count: action.count,
			}
		}
		case actionTypes.TABLE_DATA_FAILURE: {
			return {
				...state,
				loading: false,
				data: [],
				error: action.error,
			}
		}
		case actionTypes.SET_QUERY: {
			return {
				...state,
				query: action.query,
				loading: false,
			}
		}
		default:
			return state
	}
}
