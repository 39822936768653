import { AddressCard, Button, H5, H6 } from '@base'
import { Address } from '@data/model'
import { Devices, Spacings, Text } from '@styles'
import styled, { css } from 'styled-components'

const Wrapper = styled.div``

const Heading = styled(H5)`
	margin: ${Spacings.SPACING_1B} 0 ${Spacings.SPACING_3B};
	line-height: ${Text.LINE_HEIGHT_MAX};
	color: ${({ theme }) => theme.palette.text.black};
`

const AddressListWrapper = styled.div`
	display: flex;
	flex-direction: column;

	@media ${Devices.tablet} {
		flex-direction: row;
		flex-wrap: wrap;
	}
`

const AddressCardWrapper = styled.div`
	margin-bottom: ${Spacings.SPACING_3B};
	flex-grow: 1;

	border: 1px solid transparent;
	border-radius: ${Spacings.SPACING_2B};
	transition: border-color 0.15s ease-in-out;
	${({ selected }) => {
		if (selected) {
			return css`
				cursor: initial;
				border-color: ${({ theme }) => theme.palette.background.blue};
			`
		}
	}}

	${({ hoverable }) => {
		if (hoverable) {
			return css`
				cursor: pointer;
				&:hover {
					border-color: ${({ theme }) => theme.palette.background.blue};
				}
			`
		}
	}}

	@media ${Devices.tablet} {
		flex: 1 1 calc(50% - ${Spacings.SPACING_2B});
		max-width: calc(50% - ${Spacings.SPACING_2B});
		&:nth-child(2n + 1) {
			margin-right: ${Spacings.SPACING_3B};
		}
	}
`
const HeaderWrapper = styled.div`
	display: flex;
	margin-bottom: ${Spacings.SPACING_4B};
	user-select: none;
	align-items: center;
`

const Message = styled(H6)`
	color: ${({ theme }) => theme.palette.text.label};
`

const defaultLabels = {
	changeButtonLabel: 'Change Address',
	addNewButtonLabel: 'Add New Address',
	orSelectLabel: 'Or, select from existing addresses',
}

const AddressSelector = ({ savedAddresses, selectedAddress, onChange, addNewAddressHandler, onlyView, labels }) => {
	const selectSavedAddress = (address) => () => onChange(address)
	const changeAddressButtonClick = () => onChange(new Address())
	const addNew = () => addNewAddressHandler()

	const renderAddAddress = () => (selectedAddress?.pincode ? showAddedAddress() : showAddNewAddress())

	const showAddedAddress = (dontShowChangeAddressButton) => {
		return (
			selectedAddress && (
				<>
					<AddressListWrapper>
						<AddressCardWrapper selected={!dontShowChangeAddressButton}>
							<AddressCard data={selectedAddress} />
						</AddressCardWrapper>
					</AddressListWrapper>
					<HeaderWrapper>
						{!dontShowChangeAddressButton && (
							<Button small onClick={changeAddressButtonClick}>
								{labels?.changeButtonLabel ?? defaultLabels.changeButtonLabel}
							</Button>
						)}
					</HeaderWrapper>
				</>
			)
		)
	}

	const showAddNewAddress = () => (
		<HeaderWrapper>
			<Button small type='secondary' onClick={addNew}>
				{labels?.addNewButtonLabel ?? defaultLabels.addNewButtonLabel}
			</Button>
		</HeaderWrapper>
	)
	const renderAddressesFromAddressBook = () => {
		if (!selectedAddress?.pincode && Array.isArray(savedAddresses)) {
			if (savedAddresses.length === 0) {
				return <Message>No saved addresses found</Message>
			}
			return (
				<>
					<Heading>{labels?.orSelectLabel ?? defaultLabels.orSelectLabel}</Heading>
					<AddressListWrapper>
						{savedAddresses.map((address) => {
							return (
								<AddressCardWrapper onClick={selectSavedAddress(address)} hoverable>
									<AddressCard data={address} />
								</AddressCardWrapper>
							)
						})}
					</AddressListWrapper>
				</>
			)
		}
	}

	const renderAddressSelector = () => {
		if (onlyView) {
			return <Wrapper>{showAddedAddress(true)}</Wrapper>
		}

		return (
			<Wrapper>
				{renderAddAddress()}
				{renderAddressesFromAddressBook()}
			</Wrapper>
		)
	}

	return renderAddressSelector()
}

export default AddressSelector
