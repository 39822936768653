import styled, { css } from 'styled-components'
import { Text } from '@styles'

const BaseStyle = (props) => css`
	color: ${props.color};
	font-weight: ${props.fontWeight || Text.BOLD_500};
	line-height: ${props.lineHeight || Text.LINE_HEIGHT_MIN};
	margin: ${props.margin || 0};
`

export const H1 = styled.h1`
	${(props) => BaseStyle(props)}
	font-size: ${Text.XXX_LARGE};
`
export const H2 = styled.h2`
	${(props) => BaseStyle(props)}
	font-size: ${Text.X_LARGE};
`
export const H3 = styled.h3`
	${(props) => BaseStyle(props)}
	font-size: ${Text.M_LARGE};
`
export const H4 = styled.h4`
	${(props) => BaseStyle(props)}
	font-size: ${Text.LARGE};
`
export const H5 = styled.h5`
	${(props) => BaseStyle(props)}
	font-size: ${Text.MEDIUM};
`
export const H6 = styled.h6`
	${(props) => BaseStyle(props)}
	font-size: ${Text.SMALL};
`
