import { useRef } from 'react'
import Button from './Button'
import { showToast } from '@data/state/action'
import { useDispatch } from 'react-redux'
import { strings } from '@constants'
import { TOAST_TYPE } from '@base'

const UploadMultipleFileButton = ({ label, onChange, acceptFile, ...props }) => {
	const inputRef = useRef()
	const globalDispatch = useDispatch()

	const clickHandler = () => {
		if (inputRef.current && !props.disabled) {
			inputRef.current.click()
		}
	}

	const setFileAction = (filePath) => {
		if (filePath.length > 5) {
			globalDispatch(showToast(true, strings('msg_maximum_file_upload'), { type: TOAST_TYPE.ERROR }))
		} else {
			onChange(filePath)
		}
	}

	return (
		<>
			<Button {...props} onClick={clickHandler}>
				{label}
				<input
					ref={(_ref) => (inputRef.current = _ref)}
					accept={acceptFile ?? 'image/*, application/pdf'}
					style={{ display: 'none' }}
					type='file'
					onChange={(e) => {
						setFileAction(e.target.files)
					}}
					multiple
				/>
			</Button>
		</>
	)
}

export default UploadMultipleFileButton
