import styled from 'styled-components'
import { Tooltip, ResponsiveContainer, Text as TextTag, PieChart, Pie, Cell } from 'recharts'
import { CustomTooltip, PIE_CHART_COLORS } from './CustomElements'
import { Text } from '@styles'

const TextLabel = styled(TextTag)`
	font-size: ${Text.EXTRA_SMALL};
	color: ${({ theme }) => theme.palette.text.darkblack};
	width: 50px;
`
const RADIAN = Math.PI / 180

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload }) => {
	const radius = outerRadius * 1.15
	const x = cx + radius * Math.cos(-midAngle * RADIAN)
	const y = cy + radius * Math.sin(-midAngle * RADIAN)

	const label = payload[payload.labelKey]
	return (
		<TextLabel width='200' x={x} y={y} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
			{label.replace('MS ', '') + ` (${(percent * 100).toFixed(2)}%)`}
		</TextLabel>
	)
}

const PieChartComponent = ({ data }) => {
	return (
		<ResponsiveContainer width='100%' height='100%' minHeight='240px' minWidth='240px'>
			<PieChart width={500} height={300} data={data?.data}>
				<Pie
					data={data?.data}
					cx='50%'
					cy='50%'
					labelLine={false}
					label={renderCustomizedLabel}
					outerRadius={90}
					innerRadius={20}
					dataKey={data?.dataKey}
					paddingAngle={0}
				>
					{data?.data.map((item, index) => (
						<Cell
							 style={{outline: 'none'}}
							key={`pie_cell-${index}`}
							fill={PIE_CHART_COLORS[index % PIE_CHART_COLORS.length]}
							name={item?.[data?.labelKey]}
							unit={data?.unit}
							formatter={data?.formatter}
						/>
					))}
				</Pie>
				<Tooltip content={<CustomTooltip inPieChart />} />
			</PieChart>
		</ResponsiveContainer>
	)
}

export default PieChartComponent
