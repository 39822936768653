import { SearchOutlined } from '@material-ui/icons'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { Devices, Spacings, Text } from '@styles'

const SearchButtonWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	font-size: ${Text.MEDIUM};
	padding: 0 ${Spacings.SPACING_3B};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	border-radius: 50%;
	border-width: 1px;
	border-style: solid;
	border-color: ${({ theme }) => theme.palette.background.blue};
	color: ${({ theme }) => theme.palette.text.blue};
	background: ${({ theme }) => theme.palette.background.white};
	transition: background 0.15s ease-in;
	height: 2.125rem;
	width: 2.125rem;

	&:hover {
		background: ${({ theme }) => theme.palette.background.blueLight};
		color: ${({ theme }) => theme.palette.text.white};
	}

	& > span {
		display: none;
	}

	@media ${Devices.tablet} {
		border-radius: ${Spacings.SPACING_2B};
		width: fit-content;
		& > span {
			display: initial;
			margin-left: ${Spacings.SPACING_1B};
		}
	}

	& > svg {
		font-size: ${Text.X_LARGE};
	}
`

const RedDot = styled.div`
	width: ${Spacings.SPACING_2B};
	height: ${Spacings.SPACING_2B};
	border-radius: 50%;
	background: ${({ theme }) => theme.palette.background.messageRed};
	position: absolute;
	top: 0;
	right: -${Spacings.SPACING_1B};
`

const Search = ({ clickHandler, disabled = false }) => {
	const [searchParams] = useSearchParams()

	const hasSearchParameters = useMemo(() => {
		if (searchParams.has('page')) {
			searchParams.delete('page')
		}

		return !!searchParams.toString()
	}, [searchParams])

	const onClickHandler = () => { 
		if(disabled) return
		clickHandler()
	}

	return (
		<SearchButtonWrapper onClick={onClickHandler} >
			<SearchOutlined />
			<span>Search</span>
			{hasSearchParameters && <RedDot />}
		</SearchButtonWrapper>
	)
}

export default Search
