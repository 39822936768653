import styled, { css } from 'styled-components'
import { ArrowBackIos } from '@material-ui/icons'
import { useNavigate } from 'react-router-dom'
import { Spacings, Devices, Text } from '@styles'

const BackButtonWrapper = styled.span`
	justify-content: center;
	align-items: center;
	background: white;
	padding: ${Spacings.SPACING_2B};
	cursor: pointer;
	color: ${({ theme }) => theme.palette.text.black};
	margin: ${({ margin }) => margin || `0 ${Spacings.SPACING_1B} 0 0`};
	font-size: ${Text.MEDIUM};
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	& > svg {
		font-size: ${Text.MEDIUM};
	}

	${({ mobile, desktop }) => {
		if (mobile) {
			return css`
				display: inline-flex;
				@media ${Devices.laptop} {
					display: none;
				}
			`
		}

		if (desktop) {
			return css`
				display: none;
				@media ${Devices.laptop} {
					display: inline-flex;

					& > svg {
						font-size: ${Text.M_LARGE};
					}
				}
			`
		}
	}};
`

const BackButton = ({ margin, clickHandler, mobile, desktop, backHandler }) => {
	const navigate = useNavigate()
	const backClick = () => {
		if (typeof backHandler === 'function') {
			backHandler()
		} else {
			navigate(-1)
			clickHandler && clickHandler()
		}
	}
	return (
		<BackButtonWrapper mobile={mobile} desktop={desktop} margin={margin} onClick={backClick}>
			<ArrowBackIos fontSize='small' />
			{mobile && <span>Back</span>}
		</BackButtonWrapper>
	)
}

export default BackButton
