import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import { getServerUrl } from '@utils'
import { Spacings, Text } from '@styles'
import { useSwipe } from '@hooks'
import ImageRenderer from '../ImageRenderer'

const Wrapper = styled.div`
	max-width: 100%;
	width: 100%;
	position: relative;
	border: 2px solid ${({ theme }) => theme.palette.background.blueLightest};
	border-radius: ${Spacings.SPACING_2B};
	overflow: hidden;
`
const CarousalWindow = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
`
const SlidingWindow = styled.div`
	display: flex;
	height: 100%;
	max-height: 100%;
	transform: translateX(${({ translate }) => `-${translate}px`});
	transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	width: fit-content;
`
const NavigationIcon = styled.div`
	position: absolute;
	top: 50%;
	${({ isLeft }) =>
		isLeft
			? css`
					left: 0;
					border-top-right-radius: ${Spacings.SPACING_2B};
					border-bottom-right-radius: ${Spacings.SPACING_2B};
			  `
			: css`
					right: 0;
					border-top-left-radius: ${Spacings.SPACING_2B};
					border-bottom-left-radius: ${Spacings.SPACING_2B};
			  `};
	transform: translateY(-50%);
	height: ${Spacings.SPACING_12B};
	width: ${Spacings.SPACING_6B};
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({ theme }) => theme.palette.background.blueLightest};
	cursor: pointer;

	& > svg {
		font-size: ${Text.XX_LARGE};
		color: ${({ theme }) => theme.palette.background.blue};
	}
`

const Carousal = ({ urls = [], photosInView = 1, onClick }) => {
	const serverUrl = getServerUrl()
	const wrapperRef = useRef()
	const windowRef = useRef()
	const slidingWindowRef = useRef()
	const [imageWidth, setImageWidth] = useState()
	const [step, setStep] = useState(0)

	const imageUrls = useMemo(() => {
		return urls.map((src) => (src ? serverUrl + src : null)).filter(Boolean)
	}, [urls])

	const { showPrevButton, showNextButton } = useMemo(
		() => ({
			showPrevButton: step !== 0,
			showNextButton: imageUrls.length > 0 && imageUrls.length >= photosInView && step !== imageUrls.length - photosInView,
		}),
		[step, imageUrls, photosInView]
	)

	const navigate = (next) => () => setStep((s) => (next ? s + 1 : s - 1))

	const xMoveHandler = useCallback(
		(isRightSwipe) => {
			if (!isRightSwipe && showNextButton) {
				navigate(true)()
			} else if (isRightSwipe && showPrevButton) {
				navigate()()
			}
		},
		[showPrevButton, showNextButton]
	)

	useSwipe({ elem: windowRef.current, xMoveHandler })

	useEffect(() => {
		const handler = () => {
			if (wrapperRef.current) {
				const { width } = wrapperRef.current.getBoundingClientRect()

				const _imageWidth = width / photosInView
				const _imageHeight = _imageWidth * (9 / 16)
				setImageWidth(_imageWidth)
				wrapperRef.current.style.height = `${_imageHeight}px`
				wrapperRef.current.style['max-height'] = `${_imageHeight}px`
			}
		}

		handler()
		window.addEventListener('resize', handler)

		return () => {
			window.removeEventListener('resize', handler)
		}
	}, [photosInView])

	return (
		<Wrapper ref={(_r) => (wrapperRef.current = _r)}>
			<CarousalWindow ref={(_r) => (windowRef.current = _r)}>
				<SlidingWindow translate={step * imageWidth} ref={(_r) => (slidingWindowRef.current = _r)}>
					{imageUrls.map((src, index) => (
						<ImageRenderer src={src} width={imageWidth} key={src} onClick={onClick} index={index} />
					))}
				</SlidingWindow>
			</CarousalWindow>
			{showPrevButton && (
				<NavigationIcon isLeft onClick={navigate()}>
					<ChevronLeft />
				</NavigationIcon>
			)}
			{showNextButton && (
				<NavigationIcon onClick={navigate(true)}>
					<ChevronRight />
				</NavigationIcon>
			)}
		</Wrapper>
	)
}

export default Carousal
