import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { Description } from '@material-ui/icons'

import { toggleFileViewer } from '@data/state/action/root'

const FileIcon = styled(Description)`
	color: ${({ theme }) => theme.palette.background.blue};
	cursor: pointer;
`

const DocumentDownload = ({ link, icon, type, updateDocument, onDelete }) => {
	const dispatch = useDispatch()
	const [visible, setVisible] = useState(false)

	useEffect(() => {
		if (visible) {
			dispatch(toggleFileViewer(true, { files: link, onDelete }))
			setVisible(false)
		}
	}, [visible, link])

	if (icon) {
		return icon({
			onClick: () => {
				setVisible(true)
			},
		})
	}

	return (
		<>
			<FileIcon
				onClick={() => {
					setVisible(true)
				}}
			/>
		</>
	)
}

export default DocumentDownload
